import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Edit, MoreHoriz, Remove } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Color, Column, Columns, Stack, Tag } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import React, { forwardRef, useMemo, useState } from 'react';
import { DragIcon } from 'shared/icons/DragIcon';
import { LinkAnchor } from 'shared/routing/Links';
import { useNavigate } from 'shared/routing/react-router-6';
import { CopyToClipboardTooltip } from 'shared/ui/CopyToClipboardTooltip';
import { VehicleConditionIcon } from 'shared/ui/VehicleConditionIcon';
import styled from 'styled-components';
import { formatLoadStage, LoadStatus } from '../../loads/data/LoadDTO';
import { TripLoadDTO } from '../data/TripsDTO';
import { RemoveTripLoadDialog } from '../trip-details-page/RemoveTripLoadDialog';

const StyledRow = styled(TableRow)`
  display: flex;
  background-color: ${Color.White};
  min-height: 93px;
  position: relative;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) -1px -1px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 0px 4px 0px;
    z-index: 999;
    transition: all 0.2s ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      width: 100%;
      height: 2px;
      margin-top: -0.7px;
      background-color: ${Color.White};
    }
  }
`;
const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 36px;
`;

const LoadIdLink = styled(LinkAnchor)`
  word-break: break-all;
`;

export const StyledCell = styled(TableCell)<{ customWidth?: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${Color.White};
  border-bottom: 1px solid ${Color.Silver400};
  min-width: ${({ customWidth }) => customWidth || '10.5%'};
  max-width: ${({ customWidth }) => customWidth || '100%'};

  &:first-child {
    min-width: 2%;
    max-width: 2%;
  }

  &:last-child {
    flex: 1;
    align-items: center;
  }
`;

function TripLoadStatus({
  currentTerminal,
  status,
}: {
  currentTerminal?: TripLoadDTO['terminals'][number];
  status: LoadStatus;
}) {
  return currentTerminal ? (
    <Tag color="purple" variant="bold" aria-label="Terminal">
      In {currentTerminal.name}
    </Tag>
  ) : (
    <Tag
      aria-label="status tag"
      variant="subtle"
      color={
        status === 'new' ? 'teal' : status === 'picked_up' ? 'yellow' : 'green'
      }
    >
      {formatLoadStage(status)}
    </Tag>
  );
}

function TripDetailsTableRowActions({
  load: { guid },
  tripGUID,
}: {
  load: TripLoadDTO;
  tripGUID: string;
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <RemoveTripLoadDialog
        isOpen={isDeleteDialogOpen}
        loadGUID={guid}
        currentTripGUID={tripGUID}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
      />

      <IconButton
        size="small"
        edge="end"
        onClick={handleClick}
        aria-label={`action-menu-${guid}`}
        aria-controls={`action-menu-${guid}`}
      >
        <MoreHoriz htmlColor={Color.Dark100} />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate(`/loads/${guid}`);
          }}
        >
          <StyledListItemIcon>
            <Edit color="action" />
          </StyledListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsDeleteDialogOpen(true);
          }}
        >
          <StyledListItemIcon>
            <Remove color="action" />
          </StyledListItemIcon>
          Remove
        </MenuItem>
      </Menu>
    </>
  );
}

function TripDetailsLoadVehicles({
  vehicles,
}: {
  vehicles: TripLoadDTO['vehicles'];
}) {
  const [vehicle] = vehicles || [];
  const vehiclesCount = vehicles?.length || 0;

  return (
    <Stack space="xxsmall">
      <Columns space="xxsmall" align="center">
        <Column>
          <TextBox noWrap={true}>
            {vehicle?.year} {vehicle?.maker} {vehicle?.model}
          </TextBox>
        </Column>
        <Column width="content">
          <VehicleConditionIcon transportType="OPEN" isInoperable={false} />
          {/* Set transportType and isInoperable to be dynamic when backend updates API */}
        </Column>
      </Columns>

      {vehiclesCount > 1 && (
        <Tooltip
          title={
            <Stack>
              {vehicles?.map((v, i) => (
                <div key={i}>
                  {v.year} {v.maker} {v.model}
                </div>
              ))}
            </Stack>
          }
        >
          <Tag color="grey" variant="subtle">
            +{vehiclesCount - 1} more
          </Tag>
        </Tooltip>
      )}
    </Stack>
  );
}

interface TripLoadsTableRowProps {
  load: TripLoadDTO;
  isTripArchived: boolean;
  style?: React.CSSProperties;
  tripGUID: string;
}

export const TripDetailsTableRow = forwardRef<
  HTMLTableRowElement,
  TripLoadsTableRowProps
>(({ load, isTripArchived, tripGUID, ...props }, ref) => {
  const {
    guid,
    number: loadId,
    status,
    vehicles,
    pickup,
    delivery,
    terminals,
  } = load;

  const currentTerminal = useMemo(
    () => terminals.find((terminal) => terminal.is_current),
    [terminals],
  );

  return (
    <StyledRow {...props} ref={ref}>
      <StyledCell width="50px">
        <DragIcon />
      </StyledCell>
      <StyledCell width="200px">
        <Stack space="xsmall">
          <TripLoadStatus currentTerminal={currentTerminal} status={status} />

          <CopyToClipboardTooltip textToCopy={loadId} title="Copy ID">
            <LoadIdLink to={`/loads/${guid}`}>
              {loadId ? loadId : 'No Load ID'}
            </LoadIdLink>
          </CopyToClipboardTooltip>
        </Stack>
      </StyledCell>
      <StyledCell>
        <Typography>{pickup.address || 'Not available'}</Typography>
      </StyledCell>
      <StyledCell>
        <Typography>{delivery.address || 'Not available'}</Typography>
      </StyledCell>
      <StyledCell customWidth="250px">
        <TripDetailsLoadVehicles vehicles={vehicles} />
      </StyledCell>
      <StyledCell customWidth="120px">
        {!pickup.date && pickup.scheduled_date && (
          <Stack space="xxsmall">
            <TextBox color="secondary">Scheduled</TextBox>
            <Typography>
              <FormattedDate variant="ShortDate" date={pickup.scheduled_date} />
            </Typography>
          </Stack>
        )}
        {pickup.date && (
          <FormattedDate variant="ShortDate" date={pickup.date} />
        )}
      </StyledCell>
      <StyledCell customWidth="120px">
        {!delivery.date && delivery.scheduled_date && (
          <Stack space="xxsmall">
            <TextBox color="secondary">Scheduled</TextBox>
            <Typography>
              <FormattedDate
                variant="ShortDate"
                date={delivery.scheduled_date}
              />
            </Typography>
          </Stack>
        )}
        {delivery.date && (
          <FormattedDate variant="ShortDate" date={delivery.date} />
        )}
      </StyledCell>
      {!isTripArchived && (
        <StyledCell customWidth="60px">
          <TripDetailsTableRowActions load={load} tripGUID={tripGUID} />
        </StyledCell>
      )}
    </StyledRow>
  );
});

TripDetailsTableRow.displayName = 'TripDetailsTableRow';
