import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { FormikAddressAutofill } from 'shared/form/FormikAddressAutofill';
import {
  BaseFormikDrawerContentProps,
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { joinStrings } from 'shared/utils/StringUtils';
import { ContactDTO, contactEditSchema } from './data/ContactDTO';
import { useContact, useContactsAPI } from './data/ContactsAPI';

interface ContactFormDrawerContent
  extends Omit<BaseFormikDrawerContentProps, 'children'> {
  onClose: () => void;
}

function ContactFormDrawerContent({
  formik,
  actions = <FormikDrawerActions />,
  ...props
}: ContactFormDrawerContent) {
  return (
    <FormikDrawerContent {...props} formik={formik} actions={actions}>
      <Box maxWidth="432px">
        <Stack space="large">
          <Stack space="small">
            <FormikTextField
              required={true}
              fullWidth={true}
              name="name"
              label="Name"
            />

            <FormikAddressAutofill
              name="address"
              label="Address"
              onGeocode={(value) => {
                void formik.setValues((values) => {
                  const nextValues: Partial<ContactDTO> = {
                    ...values,
                    city: value.place,
                    zip: value.postcode,
                    state: value.region_short || value.region,
                    address: joinStrings(
                      ' ',
                      value.house_number,
                      value.address,
                    ),
                  };

                  return contactEditSchema.cast(nextValues);
                });
              }}
            />

            <Columns space="small" collapseBelow="desktop">
              <Column width="1/2">
                <FormikTextField fullWidth={true} name="city" label="City" />
              </Column>

              <Column width="1/4">
                <FormikTextField fullWidth={true} name="state" label="State" />
              </Column>
              <Column width="1/4">
                <FormikTextField fullWidth={true} name="zip" label="ZIP" />
              </Column>
            </Columns>
          </Stack>

          <Stack space="small">
            <FormikTextField
              fullWidth={true}
              name="contact"
              label="Contact Name"
            />

            <Columns space="small" collapseBelow="desktop">
              <Column>
                <FormikTextField fullWidth={true} name="phone" label="Phone" />
              </Column>

              <Column>
                <FormikTextField fullWidth={true} name="email" label="Email" />
              </Column>
            </Columns>

            <FormikTextField
              rows={3}
              multiline={true}
              fullWidth={true}
              name="notes"
              label="Internal Notes"
            />
          </Stack>
        </Stack>
      </Box>
    </FormikDrawerContent>
  );
}

interface ContactAddDrawerContentProps {
  onClose: () => void;
  onSuccess: (contact: ContactDTO) => void;
}

function ContactAddDrawerContent({
  onClose,
  onSuccess,
}: ContactAddDrawerContentProps) {
  const { createContact } = useContactsAPI();
  const formik = useAppFormik({
    validationSchema: contactEditSchema,
    onSubmit(values) {
      return createContact(values);
    },
    onSubmitSuccess(response: APIResponse<ContactDTO>) {
      onSuccess(response.data);
    },
    getSuccessMessage(response) {
      return response.user_message || 'New Contact added';
    },
  });

  return (
    <ContactFormDrawerContent
      formik={formik}
      onClose={onClose}
      title="Add Contact"
    />
  );
}

interface ContactEditDrawerContentProps {
  guid?: null | string;
  onClose: () => void;
}

function ContactEditDrawerContent({
  guid,
  onClose,
}: ContactEditDrawerContentProps) {
  const { data } = useContact(guid);
  const { updateContact } = useContactsAPI();
  const formik = useAppFormik({
    initialValues: data,
    validationSchema: contactEditSchema,
    onSubmit(values) {
      return updateContact(guid as string, values);
    },
    onSubmitSuccess: onClose,
    getSuccessMessage(response) {
      return response.user_message || 'Contact updated';
    },
  });

  if (!data) {
    return <ContentProgressIndicator />;
  }

  return (
    <ContactFormDrawerContent
      title="Edit Contact"
      formik={formik}
      onClose={onClose}
      actions={<FormikDrawerActions submitDisabled={!formik.dirty} />}
    />
  );
}

export type ContactFormDrawerProps =
  | ({ type: 'add'; open: boolean } & ContactAddDrawerContentProps)
  | ({ type: 'edit'; open: boolean } & ContactEditDrawerContentProps);

export function ContactFormDrawer(props: ContactFormDrawerProps) {
  return (
    <FormikDrawer open={props.open} onClose={props.onClose}>
      {props.type === 'add' && <ContactAddDrawerContent {...props} />}
      {props.type === 'edit' && <ContactEditDrawerContent {...props} />}
    </FormikDrawer>
  );
}
