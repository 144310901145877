import { Divider } from '@material-ui/core';
import { Color, Column, Columns, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { isWebView } from 'shared/constants/AppTypeConstants';
import { trackEvent } from 'shared/helpers/Analytics';
import { LinkButton } from 'shared/routing/Links';
import { ScrollToBottom } from 'shared/ui/ScrollToBottom';
import styled from 'styled-components';

// Applies customization to the first `Column` elements inner `div`
const ColumnsWrapper = styled.div`
  position: relative;
  & > div > div:first-child > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

interface StepFormProps {
  step: number;
  formContent: ReactNode;
  submitButtonText: string;
  documentPreview?: ReactNode;
  onBack?: () => void;
}

const ButtonWrapper = styled(Box)`
  position: sticky;
  bottom: 0;
  background: ${Color.White};
  padding: 20px 0;
`;

export function StepForm({
  step,
  formContent,
  submitButtonText,
  documentPreview,
  onBack,
}: StepFormProps) {
  const isMobile = useResponsiveValue(true, false);
  const { isSubmitting } = useFormikContext();
  const location = useLocation();
  const prevStep = step - 1;
  const hasBackButton = prevStep > 0;

  const wrapperElement = document.getElementById('scroll-to-top');

  return (
    <Form>
      <ColumnsWrapper>
        <Columns space="xxlarge" collapseBelow="desktop">
          <Column>
            <Box
              position="relative"
              padding={['small', 'none']}
              marginBottom="large"
            >
              {formContent}

              <ScrollToBottom wrapperElement={wrapperElement} />
            </Box>

            {isMobile && <Divider />}

            <ButtonWrapper padding={['small', 'none']}>
              <Columns space="small">
                {hasBackButton && (
                  <Column width={isMobile ? '1/2' : 'content'}>
                    {onBack ? (
                      <Button onClick={onBack} variant="neutral">
                        Back to Step {prevStep}
                      </Button>
                    ) : (
                      <LinkButton
                        variant="neutral"
                        to={`/profile/verified-carrier-application/step-${prevStep}${location.search}`}
                        onClick={() => {
                          trackEvent(
                            `CTMS: Clicked Verified Carrier Appilication Back To Step ${prevStep}`,
                          );
                        }}
                        fullWidth={isMobile}
                      >
                        {isMobile ? 'Back' : `Back to Step ${prevStep}`}
                      </LinkButton>
                    )}
                  </Column>
                )}

                <Column width={isMobile ? '1/2' : 'content'}>
                  <Button
                    type="submit"
                    pending={isSubmitting}
                    fullWidth={isMobile}
                  >
                    {isMobile ? 'Continue' : submitButtonText}
                  </Button>
                </Column>
              </Columns>
            </ButtonWrapper>
          </Column>

          {documentPreview && !isWebView && <Column>{documentPreview}</Column>}
        </Columns>
      </ColumnsWrapper>
    </Form>
  );
}
