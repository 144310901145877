import {
  Divider,
  MenuItem,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Color, Column, Columns, Inline } from '@superdispatch/ui';
import { toInteger } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { LinkButton } from 'shared/routing/Links';
import { useLocationParams } from 'shared/routing/LocationParams';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { useTripsCache, useTripsPage } from '../data/TripsAPI';
import {
  setDefaultTripLoadsOrdering,
  tripsPageParamsSchema,
  TripsSearchType,
} from '../data/TripsDTO';
import { ArchiveTripDialog } from './ArchiveTripDialog';
import { CreateTripDialog } from './CreateTripDialog';
import { DeleteTripDialog } from './DeleteTripDialog';
import { TripsSearchField } from './TripsSearchField';
import { TripsTable } from './TripsTable';
import { UnarchiveTripDialog } from './UnarchiveTripDialog';

const TableWrapper = styled.div`
  overflow: auto;
  background-color: ${Color.White};
`;

const Styled1 = styled(TablePagination).attrs({ component: 'div' })`
  & {
    /* Remove space on the right to make left aligned */
    .MuiTablePagination-spacer {
      flex: 0;
    }

    /* Remove extra pixel from top for select icon  */
    .MuiTablePagination-selectIcon {
      top: unset;
    }
  }
`;

export const SEARCH_TYPE_LABELS: Array<[TripsSearchType, string]> = [
  ['trip', 'Trip Name'],
  ['driver', 'Driver Name'],
];

interface TripsListPageProps {
  path: string;
}

export function TripsListPage({ path }: TripsListPageProps) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [counts, setCounts] = useState({ unarchived: 0, archived: 0 });
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const isInternalNameEnabled = useFlag(
    'multi_carrier_edit_internal_driver_name',
  );

  const { data, error, isLoading, isFetching, refetch } = useTripsPage(params);
  const { invalidateTrips } = useTripsCache();
  const tripsCreateMatch = useMatch('/trips/create');
  const tripsDeleteMatch = useMatch<{ guid: string }>('/trips/delete/:guid');
  const tripsArchiveMatch = useMatch<{ guid: string }>('/trips/archive/:guid');
  const tripsUnarchiveMatch = useMatch<{ guid: string }>(
    '/trips/unarchive/:guid',
  );

  useEffect(() => {
    if (!isFetching && data) {
      setCounts((prev) => {
        return { ...prev, [params.status]: data.pagination.count };
      });
    }
  }, [setCounts, params.status, data, isFetching]);

  useEffect(() => {
    setDefaultTripLoadsOrdering(params.ordering);
  }, [params.ordering]);

  const closeDialog = () => {
    navigate(-1);
  };

  return (
    <>
      <DocumentTitle title="Trips" />

      <CreateTripDialog
        isOpen={tripsCreateMatch != null}
        onClose={closeDialog}
      />

      {tripsDeleteMatch !== null && (
        <DeleteTripDialog
          onClose={closeDialog}
          tripGUID={tripsDeleteMatch.params.guid}
          onSubmitSuccess={refetch}
        />
      )}

      {tripsArchiveMatch && (
        <ArchiveTripDialog
          onClose={closeDialog}
          tripGUID={tripsArchiveMatch.params.guid}
          onSubmitSuccess={invalidateTrips}
        />
      )}

      {tripsUnarchiveMatch && (
        <UnarchiveTripDialog
          onClose={closeDialog}
          tripGUID={tripsUnarchiveMatch.params.guid}
          onSubmitSuccess={() => {
            closeDialog();
            invalidateTrips();
          }}
        />
      )}

      <PageLayout
        stickyHeader={true}
        disablePaddings={true}
        header={
          <Toolbar>
            <Columns space="xxsmall">
              <Column width="fluid">
                <Typography variant="h2">Trips</Typography>
              </Column>

              <Column width="1/3">
                <Columns space="small">
                  {isInternalNameEnabled && (
                    <Column width="content">
                      <TextField
                        select={true}
                        aria-label="search by"
                        value={params.search_type}
                        onChange={({ target: { value } }) => {
                          updateParams({
                            page: 1,
                            search_type: value as TripsSearchType,
                          });
                        }}
                      >
                        {SEARCH_TYPE_LABELS.map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Column>
                  )}
                  <Column width="fluid">
                    <TripsSearchField
                      value={params.query || ''}
                      onChange={(value) => {
                        updateParams({
                          page: 1,
                          query: value,
                        });
                      }}
                    />
                  </Column>
                </Columns>
              </Column>

              <Column width="fluid">
                <Inline horizontalAlign="right">
                  <LinkButton
                    variant="primary"
                    to={{
                      pathname: '/trips/create',
                      search,
                    }}
                  >
                    <Typography variant="h5">Create Trip</Typography>
                  </LinkButton>
                </Inline>
              </Column>
            </Columns>
          </Toolbar>
        }
      >
        <Tabs value={params.status}>
          <Tab
            value="unarchived"
            onClick={() => {
              updateParams({ status: 'unarchived', page: 1 });
            }}
            label={`Active ${
              counts.unarchived ? `(${counts.unarchived})` : ''
            }`}
          />
          <Tab
            value="archived"
            onClick={() => {
              updateParams({ status: 'archived', page: 1 });
            }}
            label={`Archived ${counts.archived ? `(${counts.archived})` : ''}`}
          />
        </Tabs>

        <Divider />

        <TableWrapper>
          <TripsTable
            trips={data?.data}
            isLoading={isLoading}
            error={error}
            path={path}
            ordering={params.ordering}
            onOrderingChange={() => {
              if (params.ordering === 'changed_at') {
                updateParams({ ordering: '-changed_at' });
              } else {
                updateParams({ ordering: 'changed_at' });
              }
            }}
          />

          {data && data.pagination.count !== 0 && (
            <Styled1
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={data.pagination.count}
              rowsPerPage={params.page_size}
              onRowsPerPageChange={(event) => {
                const pageSize = toInteger(event.target.value);
                const lastPage = Math.ceil(data.pagination.count / pageSize);

                updateParams({
                  page_size: pageSize,
                  page: params.page > lastPage ? lastPage : params.page,
                });
              }}
              page={params.page - 1}
              onPageChange={(_, pageNumber) => {
                updateParams({ page: pageNumber + 1 });
              }}
            />
          )}
        </TableWrapper>
      </PageLayout>
    </>
  );
}
