import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import {
  ComponentType,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { LoadOffersDrawer } from 'shared/modules/load-offers/LoadOffersDrawer';
import { LoadSuggestionsDrawer } from 'shared/modules/load-suggestions/LoadSuggestionsDrawer';
import styled from 'styled-components';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { useNavbarContext } from './NavbarContext';

const StyledBottomNavigation = styled(BottomNavigation)`
  background: ${Color.Dark500};
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  && {
    background: #1b2638;
    color: ${Color.Silver500};
    padding: 6px 0 0;
    line-height: 20px;
  }

  &:first-child {
    padding-left: 12px;
  }

  &:last-child {
    padding-right: 12px;
  }

  &.Mui-selected {
    color: ${Color.White};
    background: ${Color.White10};

    .MuiBottomNavigationAction-label {
      font-size: 0.75rem;
    }
  }
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-size: 12px;
    padding: 0 4px;
    top: 2px;
  }
`;

export interface NavbarBottomBarItem {
  active?: boolean;
  hasBadge?: boolean;
  badgeContent?: number;
  value: string;
  label: ReactNode;
  icon: ReactNode;
  onClick?: () => void;
  component?: ComponentType<HTMLAttributes<HTMLElement>>;
}

const paths = ['/tms/loads', '/loadboard'];

interface NavbarBottomBarProps {
  localPaths?: {
    dashboard?: string;
    loads?: string;
    contacts?: string;
    drivers?: string;
    trips?: string;
    tracking?: string;
    loadboard?: string;
    ratings?: string;
    dispatcherProfile?: string;
    carrierProfile?: string;
    reports?: string;
    settings?: string;
    subscriptionDetails?: string;
    logout?: string;
  };
  items: NavbarBottomBarItem[];
  hasMenuBadge: boolean;
}

export function NavbarBottomBar({
  items,
  localPaths,
  hasMenuBadge,
}: NavbarBottomBarProps): ReactElement {
  const { setDrawerOpen, isDrawerOpen } = useNavbarContext();
  const location = useLocation();
  const history = useHistory();
  const pathname = history.createHref(location);

  const activeItem = useMemo(
    () => items.find((item) => pathname.includes(item.value)),
    [pathname, items],
  );

  return (
    <>
      <StyledBottomNavigation
        value={activeItem?.value}
        showLabels={true}
        onChange={(_event, newValue) => {
          const foundPath = paths.find((path) => path === newValue);
          if (foundPath) {
            openExternalURL(foundPath);
          }
          if (newValue) {
            setDrawerOpen(newValue);
          }
        }}
      >
        {items.map(({ active, hasBadge, badgeContent, ...item }) => (
          <StyledBottomNavigationAction
            {...item}
            key={item.value}
            icon={
              hasBadge ? (
                <StyledBadge
                  badgeContent={badgeContent}
                  color={item.value === 'suggested-loads' ? 'primary' : 'error'}
                  overlap="rectangular"
                  max={99}
                  showZero={false}
                  variant={!badgeContent ? 'dot' : 'standard'}
                >
                  <Box>{item.icon}</Box>
                </StyledBadge>
              ) : (
                item.icon
              )
            }
          />
        ))}

        <StyledBottomNavigationAction
          value="menu"
          label="Menu"
          icon={
            <Badge
              overlap="rectangular"
              color="error"
              invisible={hasMenuBadge}
              variant="dot"
            >
              <Menu />
            </Badge>
          }
        />
      </StyledBottomNavigation>

      <LoadSuggestionsDrawer
        open={isDrawerOpen === 'suggested-loads'}
        onClose={() => {
          setDrawerOpen(null);
        }}
      />

      <LoadOffersDrawer
        open={isDrawerOpen === 'load-offers'}
        onClose={() => {
          setDrawerOpen(null);
        }}
      />

      <MobileMenuDrawer hasMenuBadge={hasMenuBadge} localPaths={localPaths} />
    </>
  );
}
