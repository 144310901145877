import {
  Badge as MuiBadge,
  ButtonBase,
  Divider,
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AccountCircle, Error, ThumbUp } from '@material-ui/icons';
import { Color, Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useCookie } from 'shared/helpers/StorageHelpers';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { SidebarLinkLegacy } from 'shared/layout/sidebar/AppSidebarMenuLegacy';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';

const ButtonContainer = styled.div<{ hasBorder: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 8px;
  background-color: ${Color.Dark500};
  border-top: ${({ hasBorder }) =>
    hasBorder ? `1px solid rgba(143, 148, 158, 0.3)` : undefined};
`;
const UserMenu = styled(Popover)`
  & .MuiPaper-root {
    min-width: 240px;
    max-width: 250px;
    margin-left: 40px;
    left: 54px !important;
  }

  & .MuiListItemIcon-root {
    min-width: 40px;
  }
`;

const UserMenuToggler = styled(ButtonBase)`
  flex-wrap: wrap;
  max-width: 100%;
`;

const Badge = styled(MuiBadge)`
  & .MuiBadge-dot {
    margin-top: 5px;
    margin-right: 5px;
    height: 16px;
    width: 16px;
    border: 2px solid ${Color.Dark500};
    border-radius: 50%;
  }
`;

const UserMenuText = styled(Typography)`
  color: ${Color.Blue50};
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px;
`;

const SuperCarrierBadge = styled(SuperCarrierBadgeIcon)`
  align-self: center;
  color: ${Color.Green300};
  margin-left: 4px;
`;

export interface CarrierMenuItemsLegacy {
  ratingsLink: SidebarLinkLegacy;
  carrierItems: SidebarLinkLegacy[];
  dispatcherItems: SidebarLinkLegacy[];
  accountItems: SidebarLinkLegacy[];
  hasMenuBadge?: boolean;
}

export function AppCarrierMenuLegacy({
  ratingsLink,
  carrierItems,
  dispatcherItems,
  accountItems,
  hasMenuBadge,
}: CarrierMenuItemsLegacy) {
  const isLoadboardResponsivenessEnabled = useFlag('loadboard_responsiveness');
  const isResponsivenessEnabled = useFlag('carrier_tms_responsiveness');
  const shouldShowCarrierRatings = useFlag('carrier_ratings');

  const { data: dispatcher } = useDispatcherProfile();
  const { data: settings } = useCarrierSettings();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const isSuperPayBankError = !!settings?.superpay.payment_error;
  const isLoadboard = import.meta.env.VITE_APP_NAME === 'slbd';

  const userName = dispatcher?.name || 'Unknown';
  const companyName =
    useCookie('companyName') || settings?.carrier.name || 'Unknown';
  const [carrierMenuNode, setCarrierMenuNode] = useState<Element>();

  const handleCloseMenu = () => {
    setCarrierMenuNode(undefined);
  };

  const carrierRating = settings?.rating || { count: 0, percentage: 0 };
  const isVerified = settings?.carrier.verification_status === 'approved';

  const color =
    isSuperPayBankError || doesBillingAddressNotExist ? 'error' : 'primary';

  return (
    <>
      <Tooltip title="Click to open menu" placement="right" enterDelay={1000}>
        <ButtonContainer
          hasBorder={!isMobile}
          data-intercom-target="sidebar-menu-profile-section"
        >
          <UserMenuToggler
            onClick={({ target }) => {
              setCarrierMenuNode(target as Element);
            }}
          >
            {isMobile &&
            ((isLoadboardResponsivenessEnabled && isLoadboard) ||
              isResponsivenessEnabled) ? (
              <Inline verticalAlign="center" space="small">
                <Badge
                  overlap="rectangular"
                  color={color}
                  invisible={hasMenuBadge}
                  variant="dot"
                >
                  <AccountCircle
                    htmlColor={Color.Silver500}
                    color="inherit"
                    fontSize="large"
                  />
                </Badge>
                <UserMenuText align="center" noWrap={true}>
                  {companyName}
                </UserMenuText>
              </Inline>
            ) : (
              <>
                <Badge
                  overlap="rectangular"
                  color={color}
                  invisible={hasMenuBadge}
                  variant="dot"
                >
                  <AccountCircle
                    htmlColor={Color.Silver500}
                    color="inherit"
                    fontSize="large"
                  />
                </Badge>
                <UserMenuText align="center" noWrap={true}>
                  {companyName}
                </UserMenuText>
              </>
            )}
          </UserMenuToggler>
        </ButtonContainer>
      </Tooltip>

      <UserMenu
        id="carrier-menu"
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorEl={carrierMenuNode}
        open={!!carrierMenuNode}
        onClose={handleCloseMenu}
      >
        <Stack>
          <Box paddingTop="small" paddingLeft="small" paddingRight="small">
            <Box
              display="flex"
              paddingBottom={shouldShowCarrierRatings ? 'xsmall' : undefined}
            >
              <Typography noWrap={true} variant="body1">
                {companyName}
              </Typography>

              {isVerified && <SuperCarrierBadge fontSize="small" />}
            </Box>

            {shouldShowCarrierRatings && (
              <Inline verticalAlign="center" space="xsmall">
                <Inline verticalAlign="center" space="xxsmall">
                  <ThumbUp fontSize="small" color="action" />

                  <Typography>{carrierRating.percentage}%</Typography>

                  <Typography color="textSecondary">
                    ({carrierRating.count})
                  </Typography>
                </Inline>

                <Link
                  component={ratingsLink.localPath ? NavLink : 'a'}
                  href="/tms/profile/overview"
                  to={ratingsLink.localPath}
                  color="primary"
                  onClick={() => {
                    handleCloseMenu();
                  }}
                >
                  View Ratings
                </Link>
              </Inline>
            )}
          </Box>

          <MenuList>
            {carrierItems.map(
              ({
                href,
                label,
                Icon,
                localPath,
                target,
                onClick,
                hasError,
                tag,
                hidden,
              }) =>
                !hidden && (
                  <MenuItem
                    href={href}
                    dense={true}
                    to={localPath}
                    key={label}
                    onClick={() => {
                      onClick?.();
                      handleCloseMenu();
                    }}
                    component={localPath ? NavLink : 'a'}
                    target={target}
                    rel="noopener noreferrer"
                  >
                    {Icon && (
                      <ListItemIcon>
                        <Icon htmlColor={Color.Dark200} />
                      </ListItemIcon>
                    )}
                    <ListItemText>
                      <Inline space="small">
                        {label}
                        {tag}
                      </Inline>
                    </ListItemText>
                    {hasError && <Error fontSize="small" color="error" />}
                  </MenuItem>
                ),
            )}
          </MenuList>

          <Divider />

          <MenuList>
            <Box paddingLeft="small" paddingRight="small">
              <Typography noWrap={true} variant="body1" color="textSecondary">
                {userName}
              </Typography>
            </Box>

            {dispatcherItems.map(
              ({
                href,
                label,
                Icon,
                RightIcon,
                localPath,
                target,
                onClick,
              }) => (
                <MenuItem
                  href={href}
                  dense={true}
                  to={localPath}
                  key={label}
                  onClick={() => {
                    onClick?.();
                    handleCloseMenu();
                  }}
                  component={localPath ? NavLink : 'a'}
                  target={target}
                  alignItems="center"
                  rel="noopener noreferrer"
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon htmlColor={Color.Dark200} />
                    </ListItemIcon>
                  )}
                  <ListItemText>{label}</ListItemText>
                  {RightIcon && (
                    <ListItemSecondaryAction>
                      <RightIcon />
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
              ),
            )}
          </MenuList>

          <Divider />

          <MenuList>
            {accountItems.map(
              ({
                href,
                label,
                Icon,
                localPath,
                target,
                dataIntercomTarget,
                onClick,
              }) => (
                <MenuItem
                  href={href}
                  dense={true}
                  to={localPath}
                  key={label}
                  onClick={() => {
                    onClick?.();
                    handleCloseMenu();
                  }}
                  component={localPath ? NavLink : 'a'}
                  target={target}
                  rel="noopener noreferrer"
                  data-intercom-target={dataIntercomTarget}
                >
                  {Icon && (
                    <ListItemIcon>
                      <Icon htmlColor={Color.Dark200} />
                    </ListItemIcon>
                  )}
                  <ListItemText>{label}</ListItemText>
                </MenuItem>
              ),
            )}
          </MenuList>
        </Stack>
      </UserMenu>
    </>
  );
}
