import { InputAdornment, Typography } from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import { Color, Inline } from '@superdispatch/ui';
import { useField } from 'formik';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import {
  FormikAddressAutofill,
  FormikAddressAutofillProps,
} from 'shared/form/FormikAddressAutofill';
import { useMapValue } from 'shared/form/LeafletMap';
import { FlagIcon } from 'shared/icons/FlagIcon';
import { hoveredFieldNameAtom } from '../trip-form-page/TripFormMap';

export function FormikVenueAddressAutofill({
  name,
  startText,
  address,
  ...props
}: Omit<FormikAddressAutofillProps, 'onGeocode'>) {
  const map = useMapValue();

  const setHoveredFieldName = useSetAtom(hoveredFieldNameAtom);
  const [isFocused, setFocused] = useState(false);
  const [field, { error: fieldError }] = useField({
    name,
  });
  const [_place, { error }, { setValue }] = useField({ name: address || '' });

  return (
    <FormikAddressAutofill
      {...props}
      name={name}
      errorMsg={error || fieldError}
      placeholder={`Custom address where the trip ${
        name.includes('start') ? 'starts' : 'ends'
      }`}
      onMouseLeave={() => {
        setHoveredFieldName('');
      }}
      onMouseOver={() => {
        setHoveredFieldName(name);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      startIcon={
        <InputAdornment position="start">
          <Inline noWrap={true} space="xsmall" verticalAlign="center">
            {error || fieldError ? (
              <ErrorRounded htmlColor={Color.Red500} />
            ) : (
              <FlagIcon
                htmlColor={
                  isFocused
                    ? Color.Blue300
                    : field.value
                    ? Color.Dark500
                    : Color.Dark100
                }
              />
            )}
            <Typography variant="body1" color="textSecondary">
              {startText}
            </Typography>
          </Inline>
        </InputAdornment>
      }
      onGeocode={(nextValue) => {
        void setValue({
          city: nextValue.region,
          lat: nextValue.latitude,
          zip: nextValue.postcode,
          lng: nextValue.longitude,
          state: nextValue.region_short,
          address: nextValue.place_name,
        });
        setHoveredFieldName(name);
        if (nextValue.latitude && nextValue.longitude) {
          map?.flyTo([nextValue.latitude, nextValue.longitude]);
        }
      }}
    />
  );
}
