import { useEffect } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { useNavigate } from 'shared/routing/react-router-6';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useOnboardingDetails } from './data/OnboardingAPI';
import { OnboardingStep } from './data/OnboardingDTO';

// steps by priority
const ONBOARDING_STEPS_PATH = {
  welcome_to_ctms: '/onboarding',
  question_how_can_ctms_make_your_job_easier: '/onboarding/questions-step-1',
  team_invitation: '/onboarding/invite-team',
};
type OnboardingStepName = keyof typeof ONBOARDING_STEPS_PATH;

function getLastPendingStepName(steps: OnboardingStep['steps']) {
  for (const step of Object.keys(
    ONBOARDING_STEPS_PATH,
  ) as OnboardingStepName[]) {
    if (steps?.[step]?.status === 'pending') {
      return step;
    }
  }
  return null;
}

export function useStartOnboarding() {
  const navigate = useNavigate();
  const isOnboardingEnabled = useFlag('ctms_onboarding');
  const isWebview = MobileAppBridge.isInjected();
  const { data: onboarding, isLoading } = useOnboardingDetails({
    enabled: isOnboardingEnabled && !isWebview,
  });

  useEffect(() => {
    if (!onboarding?.steps) return;

    const lastPendingStepName = getLastPendingStepName(onboarding.steps);

    if (lastPendingStepName && isOnboardingEnabled) {
      navigate(ONBOARDING_STEPS_PATH[lastPendingStepName]);
    }
  }, [isOnboardingEnabled, onboarding?.steps, navigate]);

  return {
    isLoading,
    onboarding,
  };
}
