import {
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { ErrorRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  OverflowText,
  Stack,
  Tag,
} from '@superdispatch/ui';
import { useField } from 'formik';
import { forwardRef, ReactNode, useMemo, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DragIcon } from 'shared/icons/DragIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { TripLoadOrderWaypoints } from '../core/TripLoadOrderWayPoints';
import { useTripLoadsList } from '../data/TripsAPI';
import { LoadForTripDTO, TripVehicleDTO } from '../data/TripsDTO';

interface OrderFieldCommonProps {
  TextFieldProps?: StandardTextFieldProps;
}

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
`;

interface TripFormLoadProps
  extends Omit<
    OrderFieldCommonProps,
    'options' | 'onChange' | 'getOptionLabel'
  > {
  startIcon?: ReactNode;
  name: string;
  guid?: string;
  loadNumber?: string;
  id: number;
}

export const TripFormLoad = forwardRef(
  (
    {
      id,
      startIcon,
      TextFieldProps,
      loadNumber,
      guid,
      name,
      ...props
    }: TripFormLoadProps,
    ref,
  ) => {
    const [field, { touched, error }, { setValue }] = useField<LoadForTripDTO>({
      name,
    });
    const [search, setSearch] = useState(loadNumber);
    const query = useDebouncedValue(search, 500);

    const { data, isFetching } = useTripLoadsList({
      q: query,
      page: 1,
      trip_guid: guid,
    });

    const errorMessage = touched && error;

    const options = useMemo(
      () => (data?.pages ? data.pages.flatMap((page) => page.data) : []),
      [data],
    );

    return (
      <Columns align="center" space="xsmall">
        <Column width="content">
          {error ? <ErrorRounded htmlColor={Color.Red500} /> : <DragIcon />}
        </Column>
        <Column width="fluid">
          <Autocomplete
            {...props}
            ref={ref}
            freeSolo={true}
            options={options}
            value={field.value}
            loading={isFetching}
            aria-label="load-list"
            disableClearable={true}
            getOptionSelected={(option, value) => option.guid === value.guid}
            getOptionLabel={(option) => option.load_id || ''}
            onChange={(_, selectedValue: LoadForTripDTO) => {
              void setValue(selectedValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...TextFieldProps}
                data-start-icon={String(!!startIcon)}
                placeholder="Select the load to add to the trip"
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                InputProps={{
                  ...TextFieldProps?.InputProps,
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startIcon && <StartIcon>{startIcon}</StartIcon>}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                variant="outlined"
                fullWidth={true}
                error={!!errorMessage}
              />
            )}
            renderOption={(option) => {
              return (
                <Stack>
                  <Columns>
                    <Column width="fluid">
                      <Columns space="xxsmall" align="center">
                        <Column width="content">
                          <Typography color="textSecondary" variant="h6">
                            LOAD ID
                          </Typography>
                        </Column>
                        <Column width="adaptive">
                          <OverflowText
                            gutterBottom={false}
                            disableUnderline={true}
                            TooltipProps={{
                              placement: 'top',
                              interactive: true,
                            }}
                          >
                            {option.load_id}
                          </OverflowText>
                        </Column>
                      </Columns>
                    </Column>

                    <Column width="content">
                      <Inline horizontalAlign="right" space="xsmall">
                        <Tag
                          variant="subtle"
                          color={getTagColor(option.status)}
                        >
                          {getTagText(option.status)}
                        </Tag>
                      </Inline>
                    </Column>
                  </Columns>

                  {option.vehicles &&
                    option.vehicles.length > 0 &&
                    getVehicles(option.vehicles)}
                  <Stack>
                    <TripLoadOrderWaypoints load={option} />
                  </Stack>
                </Stack>
              );
            }}
          />
        </Column>
      </Columns>
    );
  },
);

function getVehicles(vehicles: TripVehicleDTO[]) {
  if (vehicles.length === 1) {
    const { year, make, model } = vehicles[0] as TripVehicleDTO;
    return (
      <Typography variant="h5">
        {joinStrings(' ', year, make, model)}
      </Typography>
    );
  }
  return (
    <Typography variant="h5">
      {vehicles.length} {formatPlural(vehicles.length, 'vehicle', 'vehicles')}
    </Typography>
  );
}

function getTagColor(status: string) {
  switch (status) {
    case 'new':
      return 'teal';
    case 'picked_up':
      return 'yellow';
    default:
      return 'green';
  }
}

function getTagText(status: string) {
  switch (status) {
    case 'new':
      return 'New';
    case 'picked_up':
      return 'Picked up';
    case 'delivered':
      return 'Delivered';
    default:
      return status;
  }
}
