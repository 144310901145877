import { Inline } from '@superdispatch/ui';
import { useParams } from 'react-router-dom';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { HeaderWithLogo } from 'shared/layout/HeaderWithLogo';
import { SplashScreen } from 'shared/layout/SplashScreen';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverActivationCompletedPage } from '../core/DriverActivationCompletedPage';
import { DriverInvitationDTO } from '../data/DriverDTO';
import { useDriverInvitationDetails } from '../data/DriversAPI';
import { DriverInvitationDeclinedPage } from './DriverInvitationDeclinedPage';
import { DriverInvitationForm } from './DriverInvitationForm';
import { DriverRequestDeclinedContent } from './DriverRequestDeclinedContent';
import { ExistingDriverContent } from './ExistingDriverContent';

export function DriverInvitationPage() {
  const { invitationCode } = useParams<{ invitationCode: string }>();
  const [_, setStatus] = useSearchParam('status');
  const { data, isLoading } = useDriverInvitationDetails(invitationCode, {
    onSuccess: () => {
      setStatus('');
    },
  });

  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      <HeaderWithLogo />
      {data?.status === 'pending' ? (
        <DriverInvitationContent data={data} invitationCode={invitationCode} />
      ) : data?.status === 'activated' ? (
        <DriverActivationCompletedPage carrierName={data.carrier_name} />
      ) : data?.status === 'declined' ? (
        <Inline horizontalAlign="center">
          <DriverRequestDeclinedContent />
        </Inline>
      ) : (
        <DriverInvitationDeclinedPage />
      )}
    </>
  );
}

interface DriverInvitationContentProps {
  data: DriverInvitationDTO;
  invitationCode: string;
}

function DriverInvitationContent({
  invitationCode,
  data,
}: DriverInvitationContentProps) {
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );

  if (isMultiCarrierConnectionEnabled && !data.is_new_driver) {
    return <ExistingDriverContent data={data} />;
  }

  return <DriverInvitationForm driver={data} invitationCode={invitationCode} />;
}
