import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { AppContainer } from 'shared/app/AppContainer';
import { renderApp } from 'shared/app/AppRenderer';
import { LoginContainerPage } from 'shared/auth/LoginContainerPage';
import { RedirectToTMS } from 'shared/auth/LoginContextProvider';
import { LogoutContainerPage } from 'shared/auth/LogoutContainerPage';
import { ResetPasswordSuccessPage } from 'shared/auth/ResetPasswordSuccessPage';
import { VerifyAccountContainer } from 'shared/auth/VerifyAccountContainer';
import { VerifyAccountPage } from 'shared/auth/VerifyAccountPage';
import { ServerErrorBoundary } from 'shared/errors/ServerErrorBoundary';
import { ExperimentsContainer } from 'shared/modules/experiments/ExperimentContainer';
import { OnboardingWrapper } from 'shared/modules/onboarding/core/OnboardingWrapper';
import { OnboardingContainer } from 'shared/modules/onboarding/OnboardingContainer';
import { PageRedirect } from 'shared/routing/PageRedirect';
import { Navigate } from 'shared/routing/react-router-6';
import { CarrierApp } from './CarrierApp';
import { DispatcherInvitationPage } from './dispatchers/dispatcher-invitation/DispatcherInvitationPage';
import { DriverAccountPage } from './driver-account/DriverAccount';
import { DriverAccountContainerPage } from './driver-account/DriverAccountContainerPage';
import { DriverVerifyAccountPage } from './driver-account/DriverAccountVerifyAuthPage';
import { DriverAccountWrapper } from './driver-account/DriverAccountWrapper';
import { DriverDeleteAccountCompletionPage } from './driver-account/DriverDeleteAccountCompletionPage';
import { DriverDeleteAccountConfirmationPage } from './driver-account/DriverDeleteAccountConfirmationPage';
import { DriverDeleteAccountPage } from './driver-account/DriverDeleteAccountPage';
import { DriverAccountDeletionPage } from './drivers/driver-account-deletion/DriverAccountDeletionPage';
import { DriverActivationPage } from './drivers/driver-activation/DriverActivationPage';
import { DriverInvitationPage } from './drivers/driver-invitation/DriverInvitationPage';
import { PublicOfferContainerPage } from './offers/public-offers/PublicOfferContainerPage';

function LoadboardPageRedirectPage() {
  const { pathname, search } = useLocation();

  return <PageRedirect to={pathname + search} />;
}

function SplitOrderRedirectPage() {
  const params = useParams<{ id: string }>();
  const { search } = useLocation();

  return <Navigate to={`/loads-split/${params.id}${search}`} />;
}

function ViewOffersRedirectPage() {
  const params = useParams<{ key: string }>();
  const { search } = useLocation();

  return <Navigate to={`/public-offer/${params.key}${search}`} />;
}

renderApp(
  <AppContainer>
    <Switch>
      {import.meta.env.NODE_ENV !== 'development' && (
        <CompatRoute path="/loadboard">
          <LoadboardPageRedirectPage />
        </CompatRoute>
      )}

      <CompatRoute path="/orders/:id/split">
        <SplitOrderRedirectPage />
      </CompatRoute>
      <CompatRoute path="/offers/:key/view">
        <ViewOffersRedirectPage />
      </CompatRoute>

      {/* Public routes */}
      <CompatRoute path="/login/verify">
        <VerifyAccountContainer>
          <VerifyAccountPage />
        </VerifyAccountContainer>
      </CompatRoute>
      <CompatRoute path="/login">
        <RedirectToTMS>
          <LoginContainerPage />
        </RedirectToTMS>
      </CompatRoute>
      <CompatRoute path="/reset-password-success">
        <ResetPasswordSuccessPage />
      </CompatRoute>
      <CompatRoute path="/logout">
        <LogoutContainerPage />
      </CompatRoute>
      <CompatRoute path="/public-offer">
        <PublicOfferContainerPage />
      </CompatRoute>
      <CompatRoute path="/driver-activation/:activationCode">
        <DriverActivationPage />
      </CompatRoute>
      <CompatRoute path="/driver-invite/:invitationCode">
        <DriverInvitationPage />
      </CompatRoute>
      <CompatRoute path="/driver-account-deletion/:token">
        {/* The 'rootPath' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
        <DriverAccountDeletionPage rootPath="/driver-account-deletion/:token" />
      </CompatRoute>
      <CompatRoute path="/dispatcher-invite/:invitationCode">
        <DispatcherInvitationPage />
      </CompatRoute>

      {/* Driver Account */}
      <CompatRoute path="/driver-login">
        <DriverAccountContainerPage />
      </CompatRoute>
      <CompatRoute path="/driver-account/verify">
        <VerifyAccountContainer>
          <DriverVerifyAccountPage />
        </VerifyAccountContainer>
      </CompatRoute>
      <CompatRoute path="/driver-account/delete/completion">
        <DriverDeleteAccountCompletionPage />
      </CompatRoute>
      <CompatRoute path="/driver-account/delete/confirmation">
        <DriverAccountWrapper>
          <DriverDeleteAccountConfirmationPage />
        </DriverAccountWrapper>
      </CompatRoute>
      <CompatRoute path="/driver-account/delete">
        <DriverAccountWrapper>
          <DriverDeleteAccountPage />
        </DriverAccountWrapper>
      </CompatRoute>
      <CompatRoute path="/driver-account">
        <DriverAccountWrapper>
          <DriverAccountPage />
        </DriverAccountWrapper>
      </CompatRoute>

      <CompatRoute path="/onboarding">
        <OnboardingContainer />
      </CompatRoute>

      {/* Private Routes */}
      <Route path="*">
        <ServerErrorBoundary>
          <ExperimentsContainer>
            <OnboardingWrapper>
              <CarrierApp />
            </OnboardingWrapper>
          </ExperimentsContainer>
        </ServerErrorBoundary>
      </Route>
    </Switch>
  </AppContainer>,
);
