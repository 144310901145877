import {
  Dialog,
  DialogActions as DialogActionsMui,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDeclineExistingDriverRequest } from '../data/DriversAPI';

interface DeclineRequestDialogProps {
  open: boolean;
  onClose: () => void;
  onError: () => void;
  onSuccess: () => void;
}

const DialogActions = styled(DialogActionsMui)`
  padding: 32px 24px 16px;
`;

export function DeclineRequestDialog({
  open,
  onClose,
  onError,
  onSuccess,
}: DeclineRequestDialogProps) {
  const { invitationCode } = useParams<{ invitationCode: string }>();
  const { mutate: decline, isLoading } = useDeclineExistingDriverRequest({
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="h3">
          Do you really want to Decline an invite from a Carrier?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose} variant="neutral">
          No
        </Button>
        <Button
          pending={isLoading}
          onClick={() => {
            decline(invitationCode);
          }}
          variant="critical"
        >
          Yes, Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
}
