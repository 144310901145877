import { Box } from '@superdispatch/ui-lab';
import { atom, useAtomValue } from 'jotai';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styled from 'styled-components';

const Map = styled(MapContainer)`
  .leaflet-tile-pane {
    filter: grayscale(1) !important;
  }
  .leaflet-popup {
    margin-bottom: 10px !important;
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-close-button {
    display: none !important;
  }

  .leaflet-popup-content {
    margin: 8px !important;
  }

  .leaflet-popup-content p {
    margin: 0 !important;
  }

  width: 100%;
  height: 100%;
`;

export interface MarkerProps {
  position: [number, number];
  iconUrl: string;
  popup?: React.ReactNode;
  name: string;
}

interface LeafletMapProps {
  center: [number, number];
  zoom: number;
  markers: MarkerProps[];
  setMap: (map: L.Map) => void;
}

export default function LeafletMap({
  center,
  zoom,
  markers,
  setMap,
}: LeafletMapProps) {
  return (
    <Box position="relative" width="100%" height="100%">
      <Map ref={setMap} center={center} zoom={zoom}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers.map(({ position, iconUrl, popup }, index) => (
          <Marker key={index} icon={L.icon({ iconUrl })} position={position}>
            <Popup>{popup}</Popup>
          </Marker>
        ))}
      </Map>
    </Box>
  );
}

export const mapAtom = atom<L.Map | null>(null);
export function useMapValue() {
  return useAtomValue(mapAtom);
}
