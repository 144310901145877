import { useAPIMutation } from 'shared/api/APIMutation';
import { useAPIQuery } from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import {
  SuperPaySettingsDTO,
  superPaySettingsSchema,
} from 'shared/modules/superpay/SuperPayDTO';
import { SyncMoovStep } from './MoovDTO';

export function useSuperPaySettings() {
  return useAPIQuery(
    'superpay-settings',
    () =>
      requestCarrierAPI<APIResponse<SuperPaySettingsDTO>>(
        'GET /internal/web/payments/superpay-settings/',
      ),
    { schema: superPaySettingsSchema, refetchOnWindowFocus: false },
  );
}

export function useSyncSuperPayOnboardingStep() {
  return useAPIMutation((values: SyncMoovStep) =>
    requestCarrierAPI('POST /internal/web/payments/sync-onboarding-step/', {
      json: values,
    }),
  );
}
