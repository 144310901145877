import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  APIListQueryResult,
  BaseAPIListQueryOptions,
  findAPIListQueryItem,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { ContactDTO, ContactEditDTO, contactSchema } from './ContactDTO';

export function useContactsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateContacts() {
      void queryClient.invalidateQueries('contacts');
    }

    function findContact(guid: string) {
      return findAPIListQueryItem<ContactDTO>(
        ['contacts', 'list'],
        queryClient,
        (item) => item.guid === guid,
      );
    }

    return { findContact, invalidateContacts };
  }, [queryClient]);
}

export function useContactsAPI() {
  const { invalidateContacts } = useContactsCache();

  return useMemo(() => {
    function createContact(
      json: ContactEditDTO,
    ): Promise<APIResponse<ContactDTO>> {
      return requestCarrierAPI('POST /internal/web/contacts/', { json }).then(
        (response) => {
          invalidateContacts();
          return { ...response, data: contactSchema.cast(response.data) };
        },
      );
    }

    function updateContact(
      guid: string,
      json: ContactEditDTO,
    ): Promise<APIResponse<ContactDTO>> {
      return requestCarrierAPI(
        ['PUT /internal/web/contacts/{guid}/', { guid }],
        { json },
      ).then((response) => {
        invalidateContacts();
        return { ...response, data: contactSchema.cast(response.data) };
      });
    }

    function deleteContact(guid: string) {
      return requestCarrierAPI([
        'DELETE /internal/web/contacts/{guid}/',
        { guid },
      ]);
    }

    function batchDeleteContacts(guids: string[]) {
      return Promise.all(guids.map((guid) => deleteContact(guid)));
    }

    return {
      createContact,
      updateContact,
      deleteContact,
      batchDeleteContacts,
    };
  }, [invalidateContacts]);
}

export function useContact(
  guid: string | null | undefined,
  options?: APIQueryOptions<ContactDTO>,
): APIQueryResult<ContactDTO> {
  const { findContact } = useContactsCache();

  return useAPIQuery(
    ['contacts', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/contacts/{guid}/', { guid }]),
    {
      ...options,
      enabled: !!guid,
      schema: contactSchema,
      initialData() {
        return !guid ? undefined : findContact(guid);
      },
    },
  );
}

export interface ContactsPageParams {
  q?: string;
  page_size?: number;
}

export function useContactsList(
  params?: ContactsPageParams,
  options?: BaseAPIListQueryOptions<ContactDTO>,
  isSearchEnabled?: boolean,
): APIListQueryResult<ContactDTO> {
  return useAPIListQuery(
    ['contacts', 'list', { params }],
    (page) =>
      requestCarrierAPI([
        isSearchEnabled
          ? 'GET /internal/web/contacts/search/{?page,params*}'
          : !params?.q
          ? 'GET /internal/web/contacts/{?page,params*}'
          : 'GET /internal/web/contacts/find/{?page,params*}',
        { page, params },
      ]),
    { ...options, schema: contactSchema },
  );
}

export function useDeleteContact(
  options?: APIMutationOptions<string, unknown, string | undefined>,
): APIMutationResult<string, unknown, string | undefined> {
  const queryClient = useQueryClient();
  const { deleteContact } = useContactsAPI();
  const { invalidateContacts } = useContactsCache();

  return useAPIMutation<string, unknown, string | undefined>(
    (guid) =>
      deleteContact(guid).then((response) => {
        invalidateContacts();
        return response;
      }),
    {
      ...options,
      onMutate(guid) {
        let prevGUID: string | undefined = undefined;
        const contact = findAPIListQueryItem<ContactDTO>(
          ['contacts', 'list'],
          queryClient,
          (item) => {
            if (item.guid === guid) return true;
            prevGUID = item.guid;
            return false;
          },
        );

        return contact ? prevGUID : undefined;
      },
    },
  );
}

export function useBatchDeleteContacts(
  options?: APIMutationOptions<Set<string>, unknown, string | undefined>,
): APIMutationResult<Set<string>, unknown, string | undefined> {
  const queryClient = useQueryClient();
  const { batchDeleteContacts } = useContactsAPI();
  const { invalidateContacts } = useContactsCache();

  return useAPIMutation<Set<string>, unknown, string | undefined>(
    (guids) =>
      batchDeleteContacts(Array.from(guids)).then((responses) => {
        invalidateContacts();
        return responses[0] as APIResponse;
      }),
    {
      ...options,
      onMutate(guids) {
        let prevGUID: string | undefined = undefined;
        const contact = findAPIListQueryItem<ContactDTO>(
          ['contacts', 'list'],
          queryClient,
          (item) => {
            // Exit on first matching contact.
            if (guids.has(item.guid)) return true;
            prevGUID = item.guid;
            return false;
          },
        );

        return contact ? prevGUID : undefined;
      },
    },
  );
}

interface ContactsUploadResponse {
  count: number;
}

export function useUploadContacts(
  options?: APIMutationOptions<File, ContactsUploadResponse>,
): APIMutationResult<File, ContactsUploadResponse> {
  const { invalidateContacts } = useContactsCache();
  return useAPIMutation((file) => {
    const body = new FormData();
    body.append('file', file);
    return requestCarrierAPI<APIResponse<ContactsUploadResponse>>(
      'POST /internal/web/contacts/import/',
      { body },
    ).then((response) => {
      invalidateContacts();
      return response;
    });
  }, options);
}
