import { CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { lazy, Suspense } from 'react';
import { mapAtom } from 'shared/form/LeafletMap';
import { TripCreateDTO } from '../data/TripsDTO';
import { useTripMarkers } from '../data/useTripMarkers';
const LeafletMap = lazy(() => import('shared/form/LeafletMap'));

export const hoveredFieldNameAtom = atom('');
export function useHoveredFieldNameValue() {
  return useAtomValue(hoveredFieldNameAtom);
}

const DEFAULT_CENTER: [number, number] = [47.9128, -120.84013];
const DEFAULT_ZOOM = 8;

export function TripFormMap() {
  const setMap = useSetAtom(mapAtom);
  const formik = useFormikContext<TripCreateDTO>();
  const fieldName = useHoveredFieldNameValue();

  const { loads, start_address, end_address } = formik.values;

  const markers = useTripMarkers({
    loads,
    start_address,
    end_address,
    fieldName,
  });

  return (
    <Suspense fallback={<CircularProgress />}>
      <LeafletMap
        setMap={setMap}
        markers={markers}
        zoom={DEFAULT_ZOOM}
        center={DEFAULT_CENTER}
      />
    </Suspense>
  );
}
