import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import { FormikTextField } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { useNavigationBlock } from 'shared/routing/NavigationBlock';
import { useNavigate } from 'shared/routing/react-router-6';
import { CreateTripResponse, useTripsAPI } from '../data/TripsAPI';
import { editTripSchema } from '../data/TripsDTO';

interface CreateTripDialog {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateTripDialog({ isOpen, onClose }: CreateTripDialog) {
  const { createTrip } = useTripsAPI();
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();

  const formik = useAppFormik({
    validationSchema: editTripSchema,
    key: Number(isOpen),
    initialValues: {
      name: `Trip / ${formatDate(Date.now(), { variant: 'Date' })}`,
    },
    onSubmit: (values) => createTrip(values),
    onSubmitSuccess: (response: APIResponse<CreateTripResponse>) => {
      addSnackbar('Trip has been created', { variant: 'success' });
      navigate(`/trips/view/${response.data.guid}`);
    },
    onSubmitFailure: () => {
      addSnackbar('Error occurred while creating trip', { variant: 'error' });
    },
  });

  useNavigationBlock(
    formik.status.type !== 'submitted' &&
      formik.isSubmitting &&
      'Request is in progress. Are you sure you want to close the window?',
  );

  return (
    <FormikProvider value={formik}>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4">Create new trip</Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <FormikTextField
            name="name"
            label="Trip name"
            fullWidth={true}
            disabled={formik.isSubmitting}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="critical"
            disabled={formik.isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            autoFocus={true}
            disabled={formik.isSubmitting}
            pending={formik.isSubmitting}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </FormikProvider>
  );
}
