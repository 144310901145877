import { ONE_SECOND } from '@datadog/browser-core';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DriverDTO } from '../../drivers/data/DriverDTO';
import { useDriverList } from '../../drivers/data/DriversAPI';

export function TripFormAssignDriver() {
  const [search, setSearch] = useState<string>('');
  const debouncedText = useDebouncedValue(search, ONE_SECOND);
  const [field, { error }, { setValue }] = useField<DriverDTO>({
    name: 'driver',
  });

  const driverList = useDriverList({
    exclude_suspended: true,
    page_size: 100,
    q: debouncedText,
    exclude_join_requests: true,
  });

  const driverListWithDefaultDriver = useMemo((): DriverDTO[] => {
    const drivers = driverList.data?.pages.flatMap((page) => page.data);
    if (drivers) {
      return drivers.map((driver) => ({
        ...driver,
        name: driver.name || driver.email,
        guid: driver.guid,
        email: driver.email,
      }));
    }
    return [];
  }, [driverList.data]);

  return (
    <Autocomplete
      value={field.value}
      disableClearable={true}
      aria-label="driver-list"
      loading={driverList.isFetching}
      options={driverListWithDefaultDriver}
      getOptionLabel={(option: DriverDTO) => option.name}
      getOptionSelected={(option: DriverDTO, value: DriverDTO) =>
        option.guid === value.guid
      }
      onChange={(_, selectedValue) => {
        void setValue(selectedValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={true}
          variant="outlined"
          placeholder="Driver"
          aria-label="driver-list-input"
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          error={!!error}
          helperText={
            <Typography variant="caption" color="textSecondary">
              Loads in the trip will be reassigned to the selected driver
            </Typography>
          }
        />
      )}
    />
  );
}
