import { formatDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { createAPIError } from 'shared/api/APIError';
import { trackTripsEvent } from '../data/TripsAnalytics';
import { useTripsAPI } from '../data/TripsAPI';
import { TripCreateDTO, tripCreateSchema } from '../data/TripsDTO';
import { TripFormMap } from './TripFormMap';
import { TripFormSidebar } from './TripFormSidebar';

export function CreateTripPage() {
  const navigate = useNavigate();
  const params = useParams<{ guid: string }>();
  const { addSnackbar } = useSnackbarStack();
  const { createTrip } = useTripsAPI();

  const formik = useFormikEnhanced({
    validationSchema: tripCreateSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: `Trip / ${formatDate(Date.now(), { variant: 'Date' })}`,
      loads: [
        {
          guid: '',
          load_id: '',
          pickup_location: null,
          delivery_location: null,
          origin: '',
          destination: '',
        },
      ],
      start_name: '',
      end_name: '',
      start_address: undefined,
      end_address: undefined,
      driver: null,
    },
    validate: (values: TripCreateDTO) => {
      const { start_address, start_name, end_name, end_address } = values;
      const errorMsg = "Couldn't recognize the full address";
      const errors: Record<string, string> = {};
      if (!!start_name && !start_address) {
        errors.start_name = errorMsg;
      }
      if (!!end_name && !end_address) {
        errors.end_name = errorMsg;
      }
      return errors;
    },
    onSubmit(values: TripCreateDTO) {
      const { start_address, start_name, end_name, end_address } = values;

      const loadGuids = values.loads?.map((load) => load.guid);

      return createTrip({
        name: values.name,
        load_guids: loadGuids,
        driver_guid: values.driver?.guid,
        route: {
          origin: {
            address: start_address?.address || start_name,
            city: start_address?.city || '',
            state: start_address?.state || '',
            zip: start_address?.zip || '',
          },
          destination: {
            address: end_address?.address || end_name,
            city: end_address?.city || '',
            state: end_address?.state || '',
            zip: end_address?.zip || '',
          },
        },
      });
    },
    onSubmitSuccess: () => {
      trackTripsEvent({
        name: 'Carrier Created Trip',
      });
      navigate(`/trips/${params.guid}`);
    },
    onSubmitFailure: (e) => {
      trackTripsEvent({
        name: 'Carrier Failed to Create Trip',
      });
      const error = createAPIError<{
        load_guids: string[];
        route: {
          destination: {
            address: string;
            city: string;
            state: string;
            zip: string;
          };
          origin: {
            address: string;
            city: string;
            state: string;
            zip: string;
          };
        };
      }>(e);
      addSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box position="relative" display="flex" height="100%">
        <TripFormSidebar />
        <TripFormMap />
      </Box>
    </FormikProvider>
  );
}
