import {
  Divider,
  MenuItem,
  Tab,
  TablePagination,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { toInteger } from 'lodash-es';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { LinkButton } from 'shared/routing/Links';
import { useLocationParams } from 'shared/routing/LocationParams';
import { useMatch, useNavigate } from 'shared/routing/react-router-6';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import emptyPageDraw from './assets/empty-page-draw.svg';
import emptyPageTruckIcon from './assets/empty-page-truck-icon.svg';
import { useTripsCache, useTripsPage } from './data/TripsAPI';
import {
  setDefaultTripLoadsOrdering,
  tripsPageParamsSchema,
  TripsSearchType,
} from './data/TripsDTO';
import { ArchiveTripDialog } from './trips-list-page/ArchiveTripDialog';
import { CreateTripDialog } from './trips-list-page/CreateTripDialog';
import { DeleteTripDialog } from './trips-list-page/DeleteTripDialog';
import { TripsSearchField } from './trips-list-page/TripsSearchField';
import { TripsTable } from './trips-list-page/TripsTable';
import { UnarchiveTripDialog } from './trips-list-page/UnarchiveTripDialog';

const TableWrapper = styled.div`
  overflow: auto;
  background-color: ${Color.White};
`;

const Styled1 = styled(TablePagination).attrs({ component: 'div' })`
  & {
    /* Remove space on the right to make left aligned */
    .MuiTablePagination-spacer {
      flex: 0;
    }

    /* Remove extra pixel from top for select icon  */
    .MuiTablePagination-selectIcon {
      top: unset;
    }
  }
`;
const EmptyPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
`;

export const SEARCH_TYPE_LABELS: Array<[TripsSearchType, string]> = [
  ['trip', 'Trip Name'],
  ['driver', 'Driver Name'],
];

interface TripsListPageProps {
  path: string;
}

export function TripsList({ path }: TripsListPageProps) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const isMobile = useResponsiveValue(true, false);
  const isInternalNameEnabled = useFlag(
    'multi_carrier_edit_internal_driver_name',
  );

  const { data, refetch, isLoading } = useTripsPage(params);
  const { invalidateTrips } = useTripsCache();
  const tripsCreateMatch = useMatch('/trips/create');
  const tripsDeleteMatch = useMatch<{ guid: string }>('/trips/delete/:guid');
  const tripsArchiveMatch = useMatch<{ guid: string }>('/trips/archive/:guid');
  const tripsUnarchiveMatch = useMatch<{ guid: string }>(
    '/trips/unarchive/:guid',
  );

  useEffect(() => {
    setDefaultTripLoadsOrdering(params.ordering);
  }, [params.ordering]);

  const closeDialog = () => {
    navigate(-1);
  };

  return (
    <>
      <DocumentTitle title="Trips" />

      <CreateTripDialog
        isOpen={tripsCreateMatch != null}
        onClose={closeDialog}
      />

      {tripsDeleteMatch !== null && (
        <DeleteTripDialog
          onClose={closeDialog}
          tripGUID={tripsDeleteMatch.params.guid}
          onSubmitSuccess={refetch}
        />
      )}

      {tripsArchiveMatch && (
        <ArchiveTripDialog
          onClose={closeDialog}
          tripGUID={tripsArchiveMatch.params.guid}
          onSubmitSuccess={invalidateTrips}
        />
      )}

      {tripsUnarchiveMatch && (
        <UnarchiveTripDialog
          onClose={closeDialog}
          tripGUID={tripsUnarchiveMatch.params.guid}
          onSubmitSuccess={() => {
            closeDialog();
            invalidateTrips();
          }}
        />
      )}

      <PageLayout
        stickyHeader={true}
        disablePaddings={true}
        header={
          <Toolbar>
            <Columns space="xxsmall">
              <Column width="fluid">
                <Typography variant="h2">Trips</Typography>
              </Column>

              <Column width="1/3">
                <Columns space="small">
                  {isInternalNameEnabled && (
                    <Column width="content">
                      <TextField
                        select={true}
                        aria-label="search by"
                        value={params.search_type}
                        onChange={({ target: { value } }) => {
                          updateParams({
                            page: 1,
                            search_type: value as TripsSearchType,
                          });
                        }}
                      >
                        {SEARCH_TYPE_LABELS.map(([key, label]) => (
                          <MenuItem key={key} value={key}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Column>
                  )}
                  <Column width="fluid">
                    <TripsSearchField
                      value={params.query || ''}
                      onChange={(value) => {
                        updateParams({
                          page: 1,
                          query: value,
                        });
                      }}
                    />
                  </Column>
                </Columns>
              </Column>

              <Column width="fluid">
                <Inline horizontalAlign="right">
                  <LinkButton
                    variant="primary"
                    to={{
                      pathname: '/trips/create',
                      search,
                    }}
                  >
                    <Typography variant="h5">Create Trip</Typography>
                  </LinkButton>
                </Inline>
              </Column>
            </Columns>
          </Toolbar>
        }
      >
        {isLoading ? (
          <ContentProgressIndicator />
        ) : !data?.data.length ? (
          <EmptyPageContainer>
            <Inline verticalAlign="center">
              <Box maxWidth="380px">
                <Stack space="medium" align="center">
                  <img src={emptyPageTruckIcon} alt="empty-page-truck-icon" />
                  <Stack>
                    <Typography variant="h3" align="center">
                      No Trips
                    </Typography>
                    <Typography align="center">
                      Group loads into a trip to manage them all in one place.
                      Create a trip by clicking the button at the top right.
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              {!isMobile && <img src={emptyPageDraw} alt="empty-page-draw" />}
            </Inline>
          </EmptyPageContainer>
        ) : (
          <TripsListContent path={path} />
        )}
      </PageLayout>
    </>
  );
}

function TripsListContent({ path }: { path: string }) {
  const [params, updateParams] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const { data, error, isFetching } = useTripsPage(params);

  return (
    <>
      <Tabs value={params.status}>
        <Tab
          value="unarchived"
          onClick={() => {
            updateParams({ status: 'unarchived', page: 1 });
          }}
          label={`Active ${
            params.status === 'unarchived' ? `(${data?.pagination.count})` : ''
          }`}
        />
        <Tab
          value="archived"
          onClick={() => {
            updateParams({ status: 'archived', page: 1 });
          }}
          label={`Archived ${
            params.status === 'archived' ? `(${data?.pagination.count})` : ''
          }`}
        />
      </Tabs>

      <Divider />

      <TableWrapper>
        <TripsTable
          trips={data?.data}
          isLoading={isFetching}
          error={error}
          path={path}
          ordering={params.ordering}
          onOrderingChange={() => {
            if (params.ordering === 'changed_at') {
              updateParams({ ordering: '-changed_at' });
            } else {
              updateParams({ ordering: 'changed_at' });
            }
          }}
        />

        {data && data.pagination.count !== 0 && (
          <Styled1
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data.pagination.count}
            rowsPerPage={params.page_size}
            onRowsPerPageChange={(event) => {
              const pageSize = toInteger(event.target.value);
              const lastPage = Math.ceil(data.pagination.count / pageSize);

              updateParams({
                page_size: pageSize,
                page: params.page > lastPage ? lastPage : params.page,
              });
            }}
            page={params.page - 1}
            onPageChange={(_, pageNumber) => {
              updateParams({ page: pageNumber + 1 });
            }}
          />
        )}
      </TableWrapper>
    </>
  );
}
