import { TableRow, Typography } from '@material-ui/core';
import { Flag, Lock } from '@material-ui/icons';
import { Color, Inline, Tag } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import styled from 'styled-components';
import { TripLoadDTO } from '../data/TripsDTO';
import { StyledCell } from './TripDetailsTableRow';

const StyledRow = styled(TableRow)`
  display: flex;
  background-color: ${Color.White};
  min-height: 93px;
`;

interface TripLoadsTableRowProps {
  load?: TripLoadDTO;
  isTripArchived: boolean;
  position?: 'start' | 'end';
}

export function TripDetailsStopPointRow({
  load,
  isTripArchived,
  position,
  ...props
}: TripLoadsTableRowProps) {
  const { pickup, delivery } = load || {};

  return (
    <StyledRow {...props}>
      <StyledCell width="50px">
        <Lock htmlColor={Color.Silver500} />
      </StyledCell>
      <StyledCell>
        <Box display="inline">
          {position === 'start' && (
            <Tag color="blue" variant="subtle">
              <Inline noWrap={true} verticalAlign="center" space="xxsmall">
                <Flag fontSize="small" />
                Start
              </Inline>
            </Tag>
          )}

          {position === 'end' && (
            <Tag color="grey" variant="subtle">
              <Inline noWrap={true} verticalAlign="center" space="xxsmall">
                <Flag fontSize="small" />
                End
              </Inline>
            </Tag>
          )}
        </Box>
      </StyledCell>
      <StyledCell>
        {position === 'start' && (
          <Typography>{pickup?.address || 'Not available'}</Typography>
        )}
      </StyledCell>
      <StyledCell>
        {position === 'end' && (
          <Typography>{delivery?.address || 'Not available'}</Typography>
        )}
      </StyledCell>
      <StyledCell customWidth="250px" />
      <StyledCell customWidth="120px" />
      <StyledCell customWidth="120px" />
      {!isTripArchived && <StyledCell customWidth="60px" />}
    </StyledRow>
  );
}
