import { trackEvent } from 'shared/helpers/Analytics';

export interface TripsAnalyticsEvent {
  name:
    | 'CTMS: Opened React Trips Page'
    | 'Carrier Created Trip'
    | 'Carrier Failed to Create Trip';
}

export function trackTripsEvent(event: TripsAnalyticsEvent) {
  trackEvent(event.name);
}
