import {
  Avatar as MuiAvatar,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { Color, Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { DomainIcon } from 'shared/icons/DomainIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import { getInitials } from 'shared/utils/TextUtils';
import styled from 'styled-components';
import { DriverInvitationDTO } from '../data/DriverDTO';
import {
  DriverAcceptInvitationResponse,
  invalidateQuery,
  useAcceptExistingDriverRequest,
} from '../data/DriversAPI';
import { DeclineRequestDialog } from './DeclineRequestDialog';
const Confetti = lazy(() => import('react-confetti'));

interface DriverRequestContentProps {
  data: DriverInvitationDTO;
  onStatusUpdate: (status: string) => void;
}

const Container = styled(Box)`
  padding-top: 200px;
`;

const Avatar = styled(MuiAvatar)`
  position: absolute;
  top: 2px;
  left: 0;
  width: 62px;
  height: 62px;
  border: 3px solid ${Color.White};
  font-size: 21px;
  font-weight: 500;
  line-height: 24.5px;
  text-align: center;
`;

const WrapperImg = styled(Box)`
  position: relative;
  display: flex;
  padding-left: 50px;
`;

export function DriverRequestContent({
  data,
  onStatusUpdate,
}: DriverRequestContentProps) {
  const [isOpen, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { invitationCode } = useParams<{ invitationCode: string }>();

  const [shouldShowConfetti, setShowConfetti] = useState(true);

  const { mutate: accept, isLoading } = useAcceptExistingDriverRequest({
    onSuccess: (response: DriverAcceptInvitationResponse) => {
      if (response.url) {
        openExternalURL(response.url, { target: '_self' });
      }
      invalidateQuery(queryClient, invitationCode, 'activated');
    },
    onError: () => {
      invalidateQuery(queryClient, invitationCode, 'expired');
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const addressLine1 = joinStrings(
    ', ',
    data.carrier.address,
    data.carrier.city,
  );
  const addressLine2 = joinStrings(', ', data.carrier.state, data.carrier.zip);

  function handleAccept() {
    accept(invitationCode);
  }

  function onSuccess() {
    onStatusUpdate('declined');
    setOpen(false);
    invalidateQuery(queryClient, invitationCode, 'declined');
  }

  function onError() {
    setOpen(false);
    invalidateQuery(queryClient, invitationCode, 'expired');
  }

  const carrierInitials = getInitials(data.carrier_name);

  return (
    <Container
      width="100%"
      height="100%"
      minHeight="80vh"
      minWidth="596px"
      maxWidth="596px"
      marginTop="large"
      paddingBottom="large"
      backgroundColor="White"
      borderWidth="small"
      borderRadius="medium"
      borderColor="Silver400"
      position="relative"
    >
      <Stack space="large">
        <Inline horizontalAlign="center">
          <Box marginTop="medium">
            <Stack align="center" space="large">
              <WrapperImg>
                <Avatar
                  variant="circle"
                  alt={data.carrier_name}
                  src={data.carrier.logo}
                >
                  {carrierInitials}
                </Avatar>
                <DomainIcon />
              </WrapperImg>
              <Stack align="center" space="small">
                <Typography color="textSecondary" variant="h4">
                  {data.carrier_name} Invited You to Join
                </Typography>
                <Typography variant="h2">{data.carrier.name}</Typography>
                <Stack align="center" space="none">
                  <Typography variant="h4">{addressLine1}</Typography>
                  <Typography variant="h4">{addressLine2}</Typography>
                </Stack>
                {data.carrier.usdot && (
                  <Typography color="textSecondary" variant="h3">
                    USDOT: {data.carrier.usdot}
                  </Typography>
                )}
              </Stack>
              <Inline space="small">
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="critical"
                >
                  Decline
                </Button>
                <Button pending={isLoading} onClick={handleAccept}>
                  Accept
                </Button>
              </Inline>
            </Stack>
          </Box>
        </Inline>
      </Stack>

      <DeclineRequestDialog
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        onSuccess={onSuccess}
        onError={onError}
      />

      <Suspense fallback={<CircularProgress />}>
        {shouldShowConfetti && <Confetti width={590} height={780} />}
      </Suspense>
    </Container>
  );
}
