import { useEffect, useState } from 'react';
import { getBrowser } from 'shared/helpers/BrowserHelpers';

const CHROME_EXTENSION_PACKAGE_ID = 'npciikilfcggljgdkebakdcccmkdmpik';
const MICROSOFT_EDGE_EXTENSION_ID = 'jkdiocgccpkkjccjdobfmoabbjhjhiac';

export const CHROME_EXTENSION_STORE_URL = `https://chrome.google.com/webstore/detail/super-dispatch-importer/${CHROME_EXTENSION_PACKAGE_ID}`;
export const MICROSOFT_EDGE_EXTENSION_STORE_URL = `https://microsoftedge.microsoft.com/addons/detail/super-dispatch-connectio/${MICROSOFT_EDGE_EXTENSION_ID}`;

function checkExtension(id: string, src: string): Promise<boolean> {
  return new Promise((resolve) => {
    let image = new Image();
    image.src = 'chrome-extension://' + id + '/' + src;
    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };

    setTimeout(() => {
      resolve(false);
    }, 2000);
  });
}

export function useCheckExtension() {
  const [isInstalled, setInstalled] = useState<boolean>(false);

  useEffect(() => {
    if (getBrowser() === 'edge') {
      void Promise.all([
        checkExtension(MICROSOFT_EDGE_EXTENSION_ID, 'icons/logo.png'),
        checkExtension(CHROME_EXTENSION_PACKAGE_ID, 'icons/logo.png'), // Chrome extension can be installed in Edge as well
      ]).then(([edge, chrome]) => {
        setInstalled(edge || chrome);
      });
    } else {
      void checkExtension(CHROME_EXTENSION_PACKAGE_ID, 'icons/logo.png').then(
        (isExisted) => {
          setInstalled(isExisted);
        },
      );
    }
  }, []);

  return isInstalled;
}
