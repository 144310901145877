import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { APIListQueryResult, useAPIListQuery } from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIPageQueryResult, useAPIPageQuery } from 'shared/api/APIPageQuery';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { uploadMedia } from 'shared/data/MediaServiceAPI';
import { parseAttachmentFileType } from '../../loads/data/LoadDTO';
import {
  EditTripDTO,
  LoadForTripDTO,
  loadForTripSchema,
  ReorderTripLoadDTO,
  TripDTO,
  tripSchema,
  TripShortDTO,
  tripShortSchema,
  TripsPageParams,
} from './TripsDTO';

export interface CreateTripResponse {
  guid: string;
}

export function useTripsAPI() {
  const { invalidateTrips } = useTripsCache();

  return useMemo(() => {
    function createTrip(
      json: EditTripDTO,
    ): Promise<APIResponse<CreateTripResponse>> {
      return requestCarrierAPI('POST /internal/web/trips/', { json }).then(
        (response: APIResponse<CreateTripResponse>) => {
          invalidateTrips();
          return response;
        },
      );
    }

    function editTrip(
      guid: string,
      json: EditTripDTO,
    ): Promise<APIResponse<EditTripDTO>> {
      return requestCarrierAPI(
        ['PATCH /internal/web/trips/{guid}/edit/', { guid }],
        { json },
      ).then((response: APIResponse<EditTripDTO>) => {
        invalidateTrips();
        return response;
      });
    }

    return { createTrip, editTrip };
  }, [invalidateTrips]);
}

export function useTripsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateTrips() {
      void queryClient.invalidateQueries('trips');
    }

    function invalidateTrip(guid: string) {
      void queryClient.invalidateQueries(['trips', 'item', { guid }]);
    }

    return { invalidateTrips, invalidateTrip };
  }, [queryClient]);
}

export function useTrip(
  guid: null | string | undefined,
  options?: APIQueryOptions<TripDTO>,
): APIQueryResult<TripDTO> {
  return useAPIQuery(
    ['trips', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/trips/{guid}/', { guid }]),
    { enabled: !!guid, schema: tripSchema, ...options },
  );
}

export function useTripsPage(
  params?: TripsPageParams,
): APIPageQueryResult<TripShortDTO> {
  return useAPIPageQuery(
    ['trips', 'list', { params }],
    () => {
      const { query, search_type, ...rest } = params || {};
      return requestCarrierAPI([
        'GET /internal/web/trips/list/{?driver,query,rest*}',
        {
          rest,
          driver: search_type === 'driver' ? query : undefined,
          query: search_type === 'trip' ? query : undefined,
        },
      ]);
    },
    { schema: tripShortSchema },
  );
}

export function useRemoveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{guid}/remove/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useArchiveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/trips/{guid}/archive/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useUnarchiveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/trips/{guid}/unarchive/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useAssignTripDriver(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (driverGUID) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/assign-driver/', { guid: tripGUID }],
        { json: { driver_guid: driverGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useUnassignTripDriver(
  guid: string,
  options?: APIMutationOptions,
): APIMutationResult {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    () =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{guid}/unassign-driver/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export interface TripLoadsParams {
  page: number;
  trip_guid: string;
  q: string;
}

export function useTripLoadsList(
  params: Partial<TripLoadsParams>,
): APIListQueryResult<LoadForTripDTO> {
  return useAPIListQuery(
    ['loads', 'for-trips', { params }],
    (page) => {
      return requestCarrierAPI([
        'GET /internal/web/loads/for-trip/{?page,query*}',
        { page, query: params },
      ]);
    },
    {
      schema: loadForTripSchema,
      cacheTime: 0,
    },
  );
}

export function useAddTripLoad(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (loadGUID) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/add-load/', { guid: tripGUID }],
        { json: { load_guid: loadGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useRemoveTripLoad(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (loadGUID) =>
      requestCarrierAPI(
        ['DELETE /internal/web/trips/{guid}/remove-load/', { guid: tripGUID }],
        { json: { load_guid: loadGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useReorderTripLoads(
  guid: string,
  options?: APIMutationOptions<ReorderTripLoadDTO[]>,
): APIMutationResult<ReorderTripLoadDTO[]> {
  const { invalidateTrip } = useTripsCache();
  return useAPIMutation(
    (loads) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/reorder-loads/', { guid }],
        { json: { loads } },
      ).then((response) => {
        invalidateTrip(guid);
        return response;
      }),
    options,
  );
}

export interface AddTripAttachmentInput {
  file: File;
  tripGUID: string;
}

export function useAddTripAttachment(
  options?: APIMutationOptions<AddTripAttachmentInput>,
): APIMutationResult<AddTripAttachmentInput> {
  return useAPIMutation(
    ({ file, tripGUID }) =>
      uploadMedia(file).then((url) =>
        requestCarrierAPI(
          ['POST /internal/web/trips/{tripGUID}/attachment/', { tripGUID }],
          {
            json: {
              trip_guid: tripGUID,
              url,
              name: file.name,
              type: parseAttachmentFileType(file.name),
            },
          },
        ),
      ),
    options,
  );
}

interface DeleteTripAttachmentInput {
  attachmentGUID: string;
  tripGUID: string;
}

export function useDeleteTripAttachment(
  options?: APIMutationOptions<DeleteTripAttachmentInput>,
): APIMutationResult<DeleteTripAttachmentInput> {
  return useAPIMutation(
    ({ tripGUID, attachmentGUID }) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{tripGUID}/attachments/{attachmentGUID}/',
        { tripGUID, attachmentGUID },
      ]),
    options,
  );
}
