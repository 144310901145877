import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useRemoveTrip } from '../data/TripsAPI';

interface DeleteTripDialogProps {
  onClose: () => void;
  onSubmitSuccess?: () => void;
  tripGUID?: string;
}

export function DeleteTripDialog({
  onClose,
  onSubmitSuccess,
  tripGUID = '',
}: DeleteTripDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const remove = useRemoveTrip({
    onSuccess(response) {
      addSnackbar(response.user_message || 'Trip has been deleted', {
        variant: 'success',
      });
      onClose();
      onSubmitSuccess?.();
    },
    onError() {
      addSnackbar('Error occurred while deleting the trip', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={!!tripGUID}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title="Are you sure you want to delete this trip?"
        cancelButtonProps={{ disabled: remove.isLoading }}
        confirmButtonProps={{
          children: 'Delete',
          variant: 'critical',
          pending: remove.isLoading,
          onClick() {
            remove.mutate(tripGUID);
          },
        }}
      >
        <Typography>Deleting the trip will not remove your loads.</Typography>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
