import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Add, ArrowBack, Map, MoreHoriz } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  CardButton,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { PageLayout } from 'shared/layout/PageLayout';
import { LinkButton } from 'shared/routing/Links';
import { useNavigate } from 'shared/routing/react-router-6';
import styled from 'styled-components';
import { TripDetailsAttachment } from './core/TripDetailsAttachments';
import { TripDetailsTable } from './core/TripDetailsTable';
import { useTrip } from './data/TripsAPI';
import { TripDTO } from './data/TripsDTO';
import { ArchiveTripDialog } from './trips-list-page/ArchiveTripDialog';
import { DeleteTripDialog } from './trips-list-page/DeleteTripDialog';
import { UnarchiveTripDialog } from './trips-list-page/UnarchiveTripDialog';

const AddLoadCardButton = styled(CardButton)`
  min-height: 140px;
`;

function TripsDetailsActions({ trip }: { trip?: TripDTO }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useResponsiveValue(true, false);
  const [dialog, setDialog] = useState<'delete' | 'archive' | 'unarchive'>();
  const { guid = '' } = useParams<{ guid: string }>();
  const navigate = useNavigate();
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setDialog(undefined);
  };

  return (
    <>
      {dialog === 'delete' && (
        <DeleteTripDialog onClose={handleCloseDialog} tripGUID={guid} />
      )}

      {dialog === 'archive' && (
        <ArchiveTripDialog onClose={handleCloseDialog} tripGUID={guid} />
      )}

      {dialog === 'unarchive' && (
        <UnarchiveTripDialog
          onClose={handleCloseDialog}
          tripGUID={guid}
          onSubmitSuccess={() => {
            handleCloseDialog();
          }}
        />
      )}

      <Inline horizontalAlign="right" space="small">
        {!isMobile && (
          <>
            <LinkButton
              to={{
                pathname: `/trips/edit/${guid}`,
              }}
            >
              <Typography variant="h5">Edit Trip</Typography>
            </LinkButton>
            <AnchorButton
              startIcon={<Map />}
              variant="neutral"
              href={trip?.map_route_url || ''}
              target="_blank"
            >
              Open Map
            </AnchorButton>
          </>
        )}
        <Button
          variant="neutral"
          id="options-button"
          aria-controls={anchorEl ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreHoriz />
        </Button>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Stack space="xxsmall">
            <MenuItem
              onClick={() => {
                navigate(`/trips/edit/${guid}`);
                handleCloseMenu();
              }}
            >
              Edit Trip
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                openExternalURL(trip?.map_route_url || '', {
                  target: '_blank',
                });
                handleCloseMenu();
              }}
            >
              Open Map
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCloseMenu}>Download Trip Report</MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                setDialog(trip?.archived ? 'unarchive' : 'archive');
                handleCloseMenu();
              }}
            >
              {trip?.archived ? 'Unarchive' : 'Archive'}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDialog('delete');
                handleCloseMenu();
              }}
            >
              Delete
            </MenuItem>
          </Stack>
        </Menu>
      </Inline>
    </>
  );
}

export function TripsDetails() {
  const { guid = '' } = useParams<{ guid: string }>();
  const { addSnackbar } = useSnackbarStack();
  const navigate = useNavigate();
  const { data: trip, isLoading } = useTrip(guid, {
    onError: (error) => {
      addSnackbar(
        error.message || 'Error occurred while fetching trip details',
        {
          variant: 'error',
        },
      );
    },
  });

  return (
    <PageLayout
      loading={isLoading}
      stickyHeader={true}
      disablePaddings={true}
      header={
        <Toolbar>
          <Columns space="small">
            <Column width="fluid">
              <Inline verticalAlign="center">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBack />
                </IconButton>
                {isLoading ? (
                  <Skeleton width="240px" height="40px" />
                ) : (
                  <Typography variant="h2">{trip?.name}</Typography>
                )}
              </Inline>
            </Column>

            <Column width="content">
              {!isLoading && <TripsDetailsActions trip={trip} />}
            </Column>
          </Columns>
        </Toolbar>
      }
    >
      {!isLoading && (
        <Box padding="small" paddingLeft="medium" paddingRight="medium">
          <Stack space="small">
            <Columns space="small" collapseBelow="desktop">
              <Column width="2/3">
                {trip?.loads?.length ? (
                  <Box
                    minWidth="500px"
                    overflowY="auto"
                    borderWidth="small"
                    borderRadius="small"
                    borderColor="Silver400"
                  >
                    <TripDetailsTable
                      path={`/trips/view/${guid}`}
                      tripGUID={guid}
                      loads={trip.loads}
                      isTripArchived={!!trip.archived}
                    />
                  </Box>
                ) : (
                  <AddLoadCardButton
                    startIcon={<Add />}
                    onClick={() => {
                      navigate(`/trips/edit/${guid}`);
                    }}
                  >
                    Add Load
                  </AddLoadCardButton>
                )}
              </Column>
              <Column width="1/3" />
            </Columns>
            <Columns>
              <Column width="1/3">
                <TripDetailsAttachment trip={trip} />
              </Column>
            </Columns>
          </Stack>
        </Box>
      )}
    </PageLayout>
  );
}
