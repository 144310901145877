import {
  IconButton as MuiIconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { Add, DeleteOutline } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { Color, Column, Columns, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { FieldArray, useFormikContext } from 'formik';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { FormikVenueAddressAutofill } from '../core/FormikVenueAddressAutofill';
import { TripCreateDTO } from '../data/TripsDTO';
import { TripFormAssignDriver } from './TripFormAssignDriver';
import { TripFormLoad } from './TripFormLoad';

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;

export function TripForm() {
  const formik = useFormikContext<TripCreateDTO>();
  const { loads } = formik.values;

  const handleSortEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { destination, source } = result;
    const { index: destinationIndex } = destination;
    const { index: sourceIndex } = source;
    const loadsList = loads;

    if (loadsList) {
      const load = loads[sourceIndex];
      if (load) {
        loadsList.splice(sourceIndex, 1);
        loadsList.splice(destinationIndex, 0, load);
        void formik.setFieldValue('loads', loadsList);
      }
    }
  };

  return (
    <Box paddingTop="small">
      <Stack space="large">
        <Stack space="small">
          <FormikTextField
            name="name"
            fullWidth={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="body1" color="textSecondary">
                    Trip Name
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <TripFormAssignDriver />
        </Stack>
        <Stack space="small">
          <Stack space="xxsmall">
            <Typography variant="h4">Route</Typography>
            <Typography color="textSecondary">
              By default first and last loads will be considered as start and
              end of the trip
            </Typography>
          </Stack>
          <Stack space="medium">
            <FormikVenueAddressAutofill
              fullWidth={true}
              name="start_name"
              startText="Start"
              address="start_address"
            />

            <FieldArray
              name="loads"
              render={({ push, remove }) => {
                return (
                  <DragDropContext onDragEnd={handleSortEnd}>
                    <Droppable droppableId="TripLoadsInput">
                      {({
                        droppableProps,
                        innerRef: droppableRef,
                        placeholder,
                      }) => (
                        <Box ref={droppableRef} {...droppableProps}>
                          <Stack space="medium">
                            {loads?.map((_, id) => {
                              return (
                                <Draggable
                                  draggableId={`${id}`}
                                  index={id}
                                  key={id}
                                >
                                  {({
                                    dragHandleProps,
                                    draggableProps,
                                    innerRef,
                                  }) => (
                                    <Columns
                                      ref={innerRef}
                                      {...draggableProps}
                                      {...dragHandleProps}
                                      align="center"
                                      space="xsmall"
                                    >
                                      <Column width="fluid">
                                        <TripFormLoad
                                          id={id}
                                          name={`loads[${id}]`}
                                        />
                                      </Column>

                                      <Column width="adaptive">
                                        <IconButton
                                          onClick={() => {
                                            remove(id);
                                          }}
                                        >
                                          <DeleteOutline
                                            htmlColor={Color.Silver500}
                                          />
                                        </IconButton>
                                      </Column>
                                    </Columns>
                                  )}
                                </Draggable>
                              );
                            })}
                            {placeholder}

                            <Button
                              variant="text"
                              startIcon={<Add />}
                              onClick={() => {
                                push({ guid: '', number: '' });
                              }}
                            >
                              Add Another Load
                            </Button>
                          </Stack>
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              }}
            />

            <FormikVenueAddressAutofill
              fullWidth={true}
              name="end_name"
              startText="End"
              address="end_address"
            />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
