import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { MutationDialog } from 'shared/form/MutationDialog';
import { ConfirmDialogContent } from 'shared/ui/ConfirmDialog';
import { useArchiveTrip } from '../data/TripsAPI';

interface ArchiveTripDialogProps {
  onClose: () => void;
  onSubmitSuccess?: () => void;
  tripGUID?: string;
}

export function ArchiveTripDialog({
  onClose,
  tripGUID = '',
  onSubmitSuccess,
}: ArchiveTripDialogProps) {
  const { addSnackbar } = useSnackbarStack();

  const archive = useArchiveTrip({
    onSuccess(response) {
      addSnackbar(response.user_message || 'Trip has been archived', {
        variant: 'success',
      });

      onClose();
      onSubmitSuccess?.();
    },
    onError() {
      addSnackbar('Error occurred while deleting the trip', {
        variant: 'error',
      });
    },
  });

  return (
    <MutationDialog
      open={!!tripGUID}
      onClose={onClose}
      fullWidth={true}
      maxWidth="xs"
    >
      <ConfirmDialogContent
        onClose={onClose}
        title="Are you sure you want to archive this trip?"
        cancelButtonProps={{ disabled: archive.isLoading }}
        confirmButtonProps={{
          children: 'Archive',
          pending: archive.isLoading,
          onClick() {
            archive.mutate(tripGUID);
          },
        }}
      >
        <Typography>Trip will be moved to archived trips.</Typography>
      </ConfirmDialogContent>
    </MutationDialog>
  );
}
