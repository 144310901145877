import { Typography } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { ReactNode, useMemo } from 'react';
import { MarkerProps } from 'shared/form/LeafletMap';
import { DeliveryDownIcon } from 'shared/icons/DeliveryDownIcon';
import { OriginUpIcon } from 'shared/icons/OriginUpIcon';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import deliveryImg from '../assets/delivery.png';
import endPinFilledImg from '../assets/end-pin-filled.png';
import endPinImg from '../assets/end-pin.png';
import pickupImg from '../assets/pickup.png';
import startPinFilledImg from '../assets/start-pin-filled.png';
import startPinImg from '../assets/start-pin.png';
import { AddressDTO, CreateTripLoadDTO } from './TripsDTO';

const HintText = styled(Typography)`
  font-size: 13px;
`;

interface TripMarkersProps {
  loads?: CreateTripLoadDTO[];
  start_address?: AddressDTO | undefined;
  end_address?: AddressDTO | undefined;
  fieldName: string;
}

export function useTripMarkers({
  loads,
  start_address,
  end_address,
  fieldName,
}: TripMarkersProps) {
  const markers = useMemo((): MarkerProps[] => {
    let markersArray: MarkerProps[] = [];

    if (loads && loads.length > 0) {
      for (const load of loads) {
        if (load.pickup_location) {
          markersArray.push({
            name: 'pickup_location',
            position: load.pickup_location as [number, number],
            iconUrl: pickupImg,
            popup: (
              <MarkerPopup
                icon={<OriginUpIcon />}
                caption={load.origin}
                loadId={load.load_id}
              />
            ),
          });
        }
        if (load.delivery_location) {
          markersArray.push({
            name: 'delivery_location',
            position: load.delivery_location as [number, number],
            iconUrl: deliveryImg,
            popup: (
              <MarkerPopup
                icon={<DeliveryDownIcon />}
                caption={load.destination}
                loadId={load.load_id}
              />
            ),
          });
        }
      }
    }
    if (start_address?.lat && start_address.lng) {
      markersArray.push({
        name: 'start_name',
        position: [start_address.lat, start_address.lng],
        iconUrl: startPinImg,
        popup: (
          <MarkerPopup
            icon={<OriginUpIcon />}
            caption={joinStrings(', ', start_address.state, start_address.zip)}
            loadId={start_address.address}
          />
        ),
      });
    }
    if (end_address?.lat && end_address.lng) {
      markersArray.push({
        name: 'end_name',
        position: [end_address.lat, end_address.lng],
        iconUrl: endPinImg,
        popup: (
          <MarkerPopup
            icon={<DeliveryDownIcon />}
            caption={joinStrings(', ', end_address.state, end_address.zip)}
            loadId={end_address.address}
          />
        ),
      });
    }

    if (fieldName) {
      markersArray = markersArray.map((marker) => {
        if (marker.name === fieldName) {
          if (marker.name === 'start_name') {
            return { ...marker, iconUrl: startPinFilledImg };
          }
          if (marker.name === 'end_name') {
            return { ...marker, iconUrl: endPinFilledImg };
          }
        }

        return marker;
      });
    }
    return markersArray;
  }, [loads, start_address, end_address, fieldName]);

  return markers;
}

interface MarkerPopupProps {
  caption: string;
  loadId: string;
  icon: ReactNode;
}

function MarkerPopup({ caption, loadId, icon }: MarkerPopupProps) {
  return (
    <Columns align="center" space="xsmall">
      <Column width="content">{icon}</Column>
      <Column width="fluid">
        <Stack space="none">
          <Typography variant="caption" color="textSecondary">
            {caption}
          </Typography>
          <HintText>{loadId}</HintText>
        </Stack>
      </Column>
    </Columns>
  );
}
