import { Typography } from '@material-ui/core';
import { Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'shared/routing/react-router-6';
import WelcomeStepImage from './assets/welcome-step-image.svg';
import { OnboardingLayout } from './core/OnboardingLayout';
import { useSaveOnboardingWithErrorHandler } from './data/useSaveOnboardingWithErrorHandler';
import { useStartOnboarding } from './useStartOnboarding';

export function OnboardingWelcomeStepPage() {
  const navigate = useNavigate();
  const { onboarding } = useStartOnboarding();
  const { mutate: saveStep, isLoading } = useSaveOnboardingWithErrorHandler({
    onSuccess: () => {
      if (
        onboarding?.steps?.question_how_can_ctms_make_your_job_easier
          ?.status === 'pending'
      ) {
        navigate('/onboarding/questions-step-1');
      } else {
        navigate('/onboarding/invite-team');
      }
    },
  });

  return (
    <OnboardingLayout progress={20}>
      <Box padding="large">
        <Stack space="large" align="center">
          <img src={WelcomeStepImage} />
          <Stack align="center">
            <Typography variant="h2">Welcome to Carrier TMS </Typography>
            <Typography>
              Let&apos;s get you set up for success, in less than a minute
            </Typography>
          </Stack>
          <Button
            size="large"
            pending={isLoading}
            onClick={() => {
              saveStep({
                stepName: 'welcome_to_ctms',
                status: 'completed',
              });
            }}
          >
            Next
          </Button>
        </Stack>
      </Box>
    </OnboardingLayout>
  );
}
