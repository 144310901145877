import { QueryBuilder } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Color,
  Column,
  Columns,
  Inline,
  Tag,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { AccountOffIcon } from 'shared/icons';
import { AddDriverIcon } from 'shared/icons/AddDriverIcon';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import {
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { DriverDTO } from '../data/DriverDTO';
import { getDriverLabel } from '../data/DriverPageUtils';
import { trackDriversEvent } from '../data/DriversAnalytics';
import { useResendInviteDriver } from '../data/DriversAPI';
import { DriverInviteCancelDialog } from '../driver-invitation/DriverInvitationCancelDialog';
import { useDriversPageContext } from '../DriversPageContext';
import { DriversPageMutationDialogType } from '../DriversPageMutationDialog';
import { DriverDetailsMenuButton } from './DriverDetailMenuButton';

interface DriversPageDetailsHeaderProps {
  labelID: string;
  descriptionID: string;
  setCurrentModal: (modal: DriversPageMutationDialogType | 'edit') => void;
}

export function DriversPageDetailsHeader({
  labelID,
  descriptionID,
  setCurrentModal,
}: DriversPageDetailsHeaderProps) {
  const { currentDriverGUID, driver } = useDriversPageContext();
  const { data: subscription } = useSubscriptionSettings();
  const [isCancelInvite, setIsCancelInvite] = useState(false);
  const { data: currentDriver } = driver;
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const nextPlanEstimate = useNextPlanEstimate();

  const driverLabel = getDriverLabel({
    name: currentDriver?.name || '',
    email: currentDriver?.email || '',
  });

  const canInviteDriver = useMemo(() => {
    if (subscription?.plan) {
      const { plan } = subscription;
      const seatsCount = Number(plan.seats_count);
      const driversCount = Number(plan.drivers_count);
      return driversCount === 0 && seatsCount === 1;
    }
    return false;
  }, [subscription]);

  const { addSnackbar } = useSnackbarStack();

  const { mutate: sendResendInvite, isLoading } = useResendInviteDriver({
    onSuccess: () => {
      addSnackbar('Invite successfully sent', { variant: 'success' });
    },
  });

  const handleResendInvite = () => {
    if (currentDriverGUID) {
      sendResendInvite(currentDriverGUID);
    }
  };

  const onReinvite = () => {
    trackDriversEvent({
      name: 'Carrier Clicked Reinvite Driver',
    });
    if (shouldShowPlanUpdateDrawer) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        setCurrentModal(
          nextPlan && !canInviteDriver ? 'plan_upgrade' : 'reinvite',
        );
      });
    } else {
      setCurrentModal('reinvite');
    }
  };
  if (!currentDriver) {
    return (
      <TextBox variant="heading-2">
        <Skeleton width="200px" />
      </TextBox>
    );
  }

  return (
    <>
      <DriverInviteCancelDialog
        open={isCancelInvite}
        driver={driver.data}
        onClose={() => {
          setIsCancelInvite(false);
        }}
      />

      <Columns space="small" align="bottom">
        <DocumentTitle title={`${driverLabel} - Drivers`} />

        <Column width="fluid">
          <Columns align="bottom" space="xsmall">
            <Column width="adaptive">
              <TextBox id={labelID} variant="heading-2" noWrap={true}>
                {driverLabel}
              </TextBox>
            </Column>

            <Column width="content">
              <DriverStatus
                descriptionID={descriptionID}
                driver={currentDriver}
              />
            </Column>
          </Columns>
        </Column>

        <Column width="content">
          <Inline space="small">
            {currentDriver.connection_type === 'invite' &&
              currentDriver.connection_status === 'pending' && (
                <>
                  <Button pending={isLoading} onClick={handleResendInvite}>
                    Resend Invite
                  </Button>

                  <Button
                    variant="neutral"
                    pending={isLoading}
                    onClick={() => {
                      setIsCancelInvite(true);
                    }}
                  >
                    Cancel Invite
                  </Button>
                </>
              )}

            {isMultiCarrierConnectionEnabled &&
              currentDriver.connection_status === 'deactivated' && (
                <Button
                  variant="neutral"
                  pending={nextPlanEstimate.isFetching}
                  startIcon={<AddDriverIcon />}
                  onClick={onReinvite}
                >
                  Reinvite
                </Button>
              )}

            {(currentDriver.connection_status === 'activated' ||
              currentDriver.connection_status === 'deactivated') && (
              <DriverDetailsMenuButton
                currentDriver={currentDriver}
                setCurrentModal={setCurrentModal}
              />
            )}
          </Inline>
        </Column>
      </Columns>
    </>
  );
}

interface DriverStatusProps {
  driver: DriverDTO;
  descriptionID: string;
}

function DriverStatus({ driver, descriptionID }: DriverStatusProps) {
  if (driver.connection_status === 'deactivated') {
    return (
      <Tag variant="subtle" color="red" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (driver.is_suspended) {
    return (
      <Tag variant="subtle" color="red" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (
    driver.connection_status === 'pending' &&
    driver.connection_type === 'invite'
  ) {
    return (
      <Tag variant="subtle" color="yellow" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <QueryBuilder fontSize="small" />
          Invited
        </Inline>
      </Tag>
    );
  } else if (
    driver.connection_status === 'pending' &&
    driver.connection_type === 'join_request'
  ) {
    return (
      <Tag variant="subtle" color="blue" id={descriptionID}>
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <PersonAddAlt htmlColor={Color.Blue300} />
          Join Request
        </Inline>
      </Tag>
    );
  }

  return null;
}
